<template>
  <v-app style="height: 100vh; overflow: hidden;">
    <v-app-bar
      color="#613bcc"
      dark
      v-if="!$route.query.screenshot_headless=='1'"
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>Wstępna wycena: {{ this.price_val }}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      :permanent="$vuetify.breakpoint.mdAndUp"
      style="min-width: 300px!important;"
      v-if="!$route.query.screenshot_headless=='1'"
      
    >

      <v-list-item>

        <!-- <v-list-item-avatar>
          <v-img src="/assets/gravecreator_logo.png"></v-img>
        </v-list-item-avatar>         -->
        <v-list-item-content>
          <!-- <v-list-item-title class="text-h6">
            Grave Creator
          </v-list-item-title> -->
          <v-img
            v-bind:src="configLogo"
            width="268px"
          ></v-img>     
          <v-divider/>
          <v-list-item-subtitle>
            <a href="https://gravecreator.pl" class="logo_text">Wizualizacja nagrobków online</a>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>    
      <!-- <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>          
        </v-list-item-group>
      </v-list> -->

  <v-row justify="center" nav dense>
    <v-expansion-panels accordion>
      <v-expansion-panel v-if="price_visibility">
        <v-expansion-panel-header>Wstępna wycena
          <v-chip class="mr-6 ml-6"
            color="green"
            text-color="white">{{ this.price_val }}</v-chip>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="text-center">
            <v-row>
              <v-col>
                <v-select
            :items="realSizeHeights"
            v-model="realSizeHeight"
            label="długość cm"
            outlined
            @change="save_and_actualise_price()" 
          ></v-select>

              </v-col>
              <v-col>
                <v-select
            :items="realSizeWidths"
            v-model="realSizeWidth"
            label="szerokość cm"
            outlined
            @change="save_and_actualise_price()" 
          ></v-select>          
                
              </v-col>
            </v-row>


          <v-textarea
            :value="price_desc"
            solo
          ></v-textarea>         
          <v-btn v-if="this.showContactData" elevation="2" color="normal" :href="`tel:${this.price_customer_phone}`">{{this.price_customer_phone}} zadzwoń</v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- <v-expansion-panel>
        <v-expansion-panel-header>Wstępna wycena
          <v-chip class="mr-6 ml-6"
            color="green"
            text-color="white">0 zł</v-chip>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-textarea
          value="W przygotowaniu. Zapisz nagrobek aby otrzymać wycenę."
          ></v-textarea>            
        </v-expansion-panel-content>
      </v-expansion-panel>       -->
      <v-expansion-panel @click="onModelsExpansionPanelClick">
        <v-expansion-panel-header>Rodzaj nagrobka</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <div class="model-models">
              <!-- <p-radio class="p-image-model p-image p-plain" toggle v-for="availableModel in availableModels" v-on:change="load_model_desc(modelId)" 
                v-model="modelId" :key="availableModel.id" :value="availableModel.id" >
                <img slot="extra" class="image" :src="availableModel.icon_path" :title="availableModel.id" :alt="availableModel.id"/>
                <img slot="off-extra" class="image" :src="availableModel.icon_path" :title="availableModel.id" :alt="availableModel.id"/>
              </p-radio> -->
              <!-- <v-radio-group v-model="modelId" @change="load_model_desc(modelId)" row>
                <v-radio key="102" label="102" value="102"></v-radio>
                <v-radio key="107" label="107" value="107"></v-radio>
                <v-radio key="121" label="121" value="121"></v-radio>
                <v-radio key="122" label="122" value="122"></v-radio>
                <v-radio key="123" label="123" value="123"></v-radio>

                <v-radio >

                </v-radio>
              </v-radio-group>                               -->
              <v-radio-group v-model="modelId" row @change="load_model_desc(modelId)" >
                <v-radio class="radio_models" v-for="availableModel in availableModels" 
                  :disabled="availableModel.enable !== '1'"
                  color="primary" 
                  :key="availableModel.id" :value="availableModel.id" >{{ availableModel.id }}
                  <template v-slot:label>
                    <v-card width="100" color="white" class="d-flex align-center flex-column rounded-lg">
                    <v-img contain width="100" :src="availableModel.icon_path" :class="{'dimmed-image': availableModel.enable !== '1'}"></v-img>
                    <span v-if="availableModel.enable !== '1'" class="overlay-text">{{ 'niedostępny w pakiecie FREE' }}</span>
                    <span>{{ availableModel.id }}</span>
                    </v-card>
                  </template> 
                </v-radio>            
              </v-radio-group>                 

            </div>
          </v-container>
          <!-- <div v-for="availableModel in availableModels" :key="availableModel">
            <div>{{availableModel}}</div>


          </div> -->
          <!-- <v-radio-group v-on:change="load_model(modelId)" v-model="modelId" mandatory>
            <v-radio value="101">
              <template slot="label">
                <v-img max-height="72" max-width="100" src="/assets/icons/2.png" title="101"></v-img>      
              </template>      
            </v-radio>

            <v-radio value="107">
              <template slot="label">
                <v-img max-height="72" max-width="100" src="/assets/icons/8.png" title="107"></v-img>      
              </template>      
            </v-radio>

            <v-radio value="108">
              <template slot="label">
                <v-img max-height="72" max-width="100" src="/assets/icons/9.png" title="108"></v-img>      
              </template>      
            </v-radio>                                                      
            <v-radio value="109">
              <template slot="label">
                <v-img max-height="72" max-width="100" src="/assets/icons/10.png" title="109"></v-img>      
              </template>      
            </v-radio>                                                      
            <v-radio value="110">
              <template slot="label">
                <v-img max-height="72" max-width="100" src="/assets/icons/11.png" title="110"></v-img>      
              </template>      
            </v-radio>                                                      
            <v-radio value="111">
              <template slot="label">
                <v-img max-height="72" max-width="100" src="/assets/icons/12.png" title="111"></v-img>      
              </template>      
            </v-radio>                                                      
            <v-radio value="112">
              <template slot="label">
                <v-img max-height="72" max-width="100" src="/assets/icons/13.png" title="112"></v-img>      
              </template>      
            </v-radio>         
            <v-radio value="113">
              <template slot="label">
                <v-img max-height="72" max-width="100" src="/assets/icons/14.png" title="113"></v-img>      
              </template>      
            </v-radio>                                                                                                                              
          </v-radio-group> -->

          <v-alert
            border="top"
            colored-border
            type="info"
            elevation="2"
          >
            Jeśli brakuje wzoru prosimy o maila: <a href="mailto:kontakt@gravecreator.pl?subject=Brakujący wzór nagrobka">kontakt@gravecreator.pl</a>
          </v-alert>

        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- <v-expansion-panel @click="onTablesExpansionPanelClick">
        <v-expansion-panel-header>Rodzaj tablicy</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-radio-group v-model="modelTablica" mandatory>
              <v-radio value="1">
                <template slot="label">
                  <v-img max-height="90" max-width="100" src="/assets/icons/m39.png"></v-img>      
                </template>                      
              </v-radio>
              <v-radio value="2">
                <template slot="label">
                  <v-img max-height="90" max-width="100" src="/assets/icons/m37.png"></v-img>      
                </template>                      
              </v-radio>
              <v-radio value="3">
                <template slot="label">
                  <v-img max-height="90" max-width="100" src="/assets/icons/m35.png"></v-img>      
                </template>                      
              </v-radio>
              <v-radio value="4">
                <template slot="label">
                  <v-img max-height="90" max-width="100" src="/assets/icons/m34.png"></v-img>      
                </template>                      
              </v-radio>                                          

          </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>       -->
      <v-expansion-panel @click="onColorsExpansionPanelClick">
        <v-expansion-panel-header>Kolor nagrobka</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid v-if="modelData[modelId]">

            <div v-for="modelRow in modelData[modelId].elements" :key="modelRow.id">
              <v-expansion-panels popout>
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ modelRow.caption?modelRow.caption:modelRow.name }}</v-expansion-panel-header>
                  <v-expansion-panel-content>

                      <div class="model-colour">
                        <!-- <v-chip>{{ modelRow.caption?modelRow.caption:modelRow.name }}</v-chip> -->
                        <!-- <p-radio class="p-image-color p-image p-plain" toggle v-for="textureData in texturesData" 
                          v-on:change="save_and_actualise_price()" v-model="modelColours[modelRow.id]" 
                          :key="textureData.id" :value="textureData.value">
                          <img slot="extra" class="image" :src="textureData.icon_path"/>
                          <img slot="off-extra" class="image" :src="textureData.icon_path"/>
                        </p-radio> -->
                        <v-radio-group v-model="modelColours[modelRow.id]" row>
                          <v-radio class="radio_color" v-for="textureData in texturesData" @change="save_and_actualise_price()" 
                            :disabled="textureData.enable !== '1'"  
                            color="primary"   
                            :key="textureData.id" :value="textureData.value" >
                            <template v-slot:label>
                              <v-card width="90" color="white" class="d-flex align-center flex-column rounded-lg">
                              <v-img contain width="90" :src="textureData.icon_path" :class="{'dimmed-image': textureData.enable !== '1'}"></v-img>
                              <span v-if="textureData.enable !== '1'" class="overlay-text">{{ 'niedostępny w pakiecie FREE' }}</span>
                              <!-- <span>{{ textureData.name }} <br/> {{ textureData.price }} zł/m2</span> -->
                              </v-card>
                            </template> 
                          </v-radio>            
                        </v-radio-group>                

                      </div>

                      <v-alert
                        border="top"
                        colored-border
                        type="info"
                        elevation="2"
                      >
                        Jeśli brakuje koloru granitu prosimy o maila: <a href="mailto:kontakt@gravecreator.pl?subject=Brakujący granit">kontakt@gravecreator.pl</a>
                      </v-alert>                      
                    
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>


            <!-- <div v-for="modelRow in modelData[modelId].elements" :key="modelRow.id">
              <div class="text-center d-flex align-center justify-space-around">*
                
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    < !-- <span
                      v-bind="attrs"
                      v-on="on"
                    >This text has a tooltip</span> -- >
<v-img src="/assets/textures/granite_white_512.jpg"
v-bind="attrs"
                      v-on="on"></v-img>                    
                  </template>
                  <span>Tooltip</span>
                </v-tooltip>                
              </div>
            </div> -->

            <!-- <div>
              <p-radio name="radio1" class="p-image p-plain" toggle>
                <img slot="extra" class="image" src="/assets/black-stones-tile32.jpg"/>
                <img slot="off-extra" class="image" src="/assets/black-stones-tile32.jpg"/>
                <label slot="off-label"></label>
              </p-radio>
              <p-radio name="radio1" class="p-image p-plain" toggle>
                <img slot="extra" class="image" src="/assets/granite_green_32.jpg"/>
                <img slot="off-extra" class="image" src="/assets/granite_green_32.jpg"/>
                <label slot="off-label"></label>              
              </p-radio>
              <p-radio name="radio1" class="p-image p-plain" toggle>
                <img slot="extra" class="image" src="/assets/granite_red_32.jpg"/>
                <img slot="off-extra" class="image" src="/assets/granite_red_32.jpg"/>
                <label slot="off-label"></label>              
              </p-radio>
              <p-radio name="radio1" class="p-image p-plain" toggle>
                <img slot="extra" class="image" src="/assets/granite_black_32.jpg"/>
                <img slot="off-extra" class="image" src="/assets/granite_black_32.jpg"/>
                <label slot="off-label"></label>              
              </p-radio>              
            </div> -->

            <!--div v-for="(modelDataColour, index) in modelColours" :key="index"-->
            <!-- <p>{{ modelColour }}</p> -->
            <!-- <v-radio-group row v-model="modelColours[index]" mandatory>
              <v-radio
                label="gray"
                value="1"
              ></v-radio>
              <v-radio
                label="green"
                value="2"
              ></v-radio>
              <v-radio
                label="red"
                value="3"
              ></v-radio>              
              <v-radio
                label="black"
                value="4"
              ></v-radio>                            
            </v-radio-group> -->
            <!--div class="example ex1">
              <h4>Select Color</h4>
              <label class="radio gray">
                <input type="radio" v-model="modelColours[index]" name="group1" value="1"/>
                <span>.</span>
              </label>
              <label class="radio green">
                <input type="radio" v-model="modelColours[index]" name="group1" value="2"/>
                <span>.</span>
              </label>
              <label class="radio red">
                <input type="radio" v-model="modelColours[index]" name="group1" value="3"/>
                <span>.</span>
              </label>
              <label class="radio black2">
                <input type="radio" v-model="modelColours[index]" name="group1" value="4"/>
                <span>.</span>
              </label>

            </div>            
            </div-->

            <!-- <p>środek</p>
            <v-radio-group v-model="modelColour2" mandatory>
              <v-radio
                label="gray"
                value="1"
              ></v-radio>
              <v-radio
                label="green"
                value="2"
              ></v-radio>
              <v-radio
                label="red"
                value="3"
              ></v-radio>   
              <v-radio
                label="black"
                value="4"
              ></v-radio>                         
            </v-radio-group>

            <p>podstawa</p>
            <v-radio-group v-model="modelColour3" mandatory>
              <v-radio
                label="gray"
                value="1"
              ></v-radio>
              <v-radio
                label="green"
                value="2"
              ></v-radio>
              <v-radio
                label="red"
                value="3"
              ></v-radio>        
              <v-radio
                label="black"
                value="4"
              ></v-radio>                    
            </v-radio-group>             -->

          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

        <v-expansion-panel @click="onShapesExpansionPanelClick">
        <v-expansion-panel-header>Krzyż</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="(modelShape, index) in modelShapes" :key="index">
              <v-expansion-panel-header disable-icon-rotate>{{ modelShapesData[index].name }}
                <!--v-spacer></v-spacer>
                <v-icon @click.stop="remove_shape(index)" color="red">mdi-close</v-icon-->
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container >
                  <v-radio-group v-model="modelShapes[index]" mandatory>
                    <v-radio v-on:change="save_and_actualise_price()" v-for="modelShapeData in modelShapesData" v-bind:value="modelShapeData.id" :key="modelShapeData.id">
                      <template slot="label">
                        <v-img max-height="60" max-width="40" v-bind:src="modelShapeData.img_path"></v-img>
                      </template>                            
                    </v-radio>
                  </v-radio-group>
                </v-container> 
                <v-container >
                  <v-radio-group v-model="modelShapesColours[index]" mandatory>
                    <v-radio
                      label="black"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="white"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="gold"
                      value="3"
                    ></v-radio>              
                  </v-radio-group>
                </v-container>      
                <v-container fluid>
                  <p>rozmiar</p>
                  <v-slider
                    v-model="modelShapesSize[index]"
                    max="200"
                    min="1"
                  ></v-slider>
                </v-container>                                
                                
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- <v-container fluid>
            <v-radio-group v-model="modelShape1" mandatory>
              <v-radio
                label="1"
                value="1"
              ></v-radio>
              <v-radio
                label="2"
                value="2"
              ></v-radio>
              <v-radio
                label="3"
                value="3"
              ></v-radio>              
            </v-radio-group>
          </v-container> -->

          <v-container fluid>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="indigo"
              @click="add_shape()"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
            dodaj krzyż
          </v-container>             
                    
        </v-expansion-panel-content>
      </v-expansion-panel>

          <v-expansion-panel @click="onTextsExpansionPanelClick">
            <v-expansion-panel-header>Inskrypcje</v-expansion-panel-header>
            <v-expansion-panel-content>

              <v-expansion-panels accordion>

                <v-expansion-panel v-for="(modelTxt, index) in modelTxts" :key="index">
                  <v-expansion-panel-header disable-icon-rotate>{{ modelTxt }}
                    <!-- <template v-slot:actions>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="indigo"
                        @click="remove_text()"
                      >
                        <v-icon color="white">
                          mdi-minus
                        </v-icon>
                      </v-btn>
                    </template> -->
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container >
                      <v-textarea filled
                          label="Inskrypcja"
                          value=""
                          v-on:change="save_and_actualise_price()" 
                          v-model="modelTxts[index]"></v-textarea>
                    </v-container>
                    <v-container >
                      <v-radio-group v-model="modelTxtsFontsStyle[index]" mandatory>
                        <v-radio
                          label="normal"
                          value="1"
                        ></v-radio>
                        <v-radio
                          label="italic"
                          value="2"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>                                     
                    <v-container >
                      <v-radio-group v-model="modelTxtsFonts[index]" mandatory>
                        <v-radio
                          label="Times"
                          value="1"
                        ></v-radio>
                        <v-radio
                          label="Arial"
                          value="2"
                        ></v-radio>
                        <v-radio
                          label="Arial Narrow"
                          value="3"
                        ></v-radio>       
                        <v-radio
                          label="Century Gothic"
                          value="4"
                        ></v-radio>                                   
                      </v-radio-group>
                    </v-container>                             
                    <v-container >
                      <v-radio-group v-model="modelTxtsColours[index]" mandatory>
                        <v-radio
                          label="black"
                          value="1"
                        ></v-radio>
                        <v-radio
                          label="white"
                          value="2"
                        ></v-radio>
                        <v-radio
                          label="gold"
                          value="3"
                        ></v-radio>              
                      </v-radio-group>
                    </v-container>         
                    <v-container fluid>
                      <p>rozmiar</p>
                      <v-slider
                        v-model="modelTxtsSize[index]"
                        max="200"
                        min="1"
                      ></v-slider>
                    </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>

                <!-- <v-expansion-panel>
                  <v-expansion-panel-header>Inskrypcja 2</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container >
                      <v-textarea filled name="input-7-1"
                          label="Inskrypcja"
                          value=""
                          v-model="modelTxt2" ></v-textarea>
                    </v-container>
                    <v-container >
                      <v-radio-group v-model="modelTextColour1" mandatory>
                        <v-radio
                          label="black"
                          value="1"
                        ></v-radio>
                        <v-radio
                          label="white"
                          value="2"
                        ></v-radio>
                        <v-radio
                          label="gold"
                          value="3"
                        ></v-radio>              
                      </v-radio-group>
                    </v-container>         
                    <v-container fluid>
                      <p>rozmiar</p>
                      <v-slider
                        v-model="modelTxt1Size"
                        max="20"
                        min="1"
                      ></v-slider>
                    </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>               -->
          </v-expansion-panels>

          <v-container fluid>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="indigo"
              @click="add_text()"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
            dodaj inskrypcję
          </v-container>

        </v-expansion-panel-content>
      </v-expansion-panel>      
    </v-expansion-panels>
  </v-row>

  <v-row justify="center">

  </v-row>

  <v-container fluid>
    <div class="text-center">
    <!-- <v-btn elevation="2" @click="$refs.model3d2.make_screenshot()">screenshot</v-btn> -->
    <!-- <v-btn elevation="2" @click="$refs.model3d2.save_screenshot()">save screenshot (size!)</v-btn> -->
    <!-- <v-btn elevation="2" @click="$refs.model3d2.show_model_json()">json</v-btn> -->
    <!--v-btn v-if="this.orderKey" elevation="2" @click="save_and_show_link()">save and show link</v-btn-->
    
    <!-- zapis z wysyłką na sms i email-->
    <v-btn v-if="this.orderKey && !this.userKey" elevation="2" :loading="saving" color="warning" @click="saving=true;save_and_close_window()">zapisz</v-btn>
    <!-- zwykly zapis -->
    <v-btn v-if="this.orderKey && this.userKey" elevation="2" color="warning" @click="dialog3=true;">wyślij emailem i smsem</v-btn>

    <!-- odkomentowac aby latwo eksportowac do konsoli wspolrzedne tekstow i ksztaltow -->
    <!-- <v-text-field v-model="z_coords"></v-text-field>
    <v-btn elevation="2" color="warning" @click="print_obj_txt_coords()">generuj obj i txt</v-btn> -->
  
  </div>
    
    <!--v-dialog
      v-model="dialog2"
      width="800px"
    >
      <template v-slot:activator="{ on, attrs2 }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs2"
          v-on="on"
        >
          Rodzaj nagrobka err
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Wybierz wzór nagrobka</span>
        </v-card-title>
        <v-card-text>

          <v-row>
            <v-col
              v-for="availableModel in availableModels"
              :key="availableModel.id"
              :value="availableModel.id"
              class="d-flex child-flex"
              cols="4"
              @click="dialog2=false;load_model_desc(availableModel.id)"
           >
              <v-img
                :src="availableModel.icon_path"
                :lazy-src="availableModel.icon_path"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>

        </v-card-text>

      </v-card>
    </v-dialog-->    


    <v-dialog
      v-model="dialog3"
      width="500"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          link
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Podaj email i nr telefonu
        </v-card-title>

        <v-card-text>
          <!-- <input id="html_dialog_text" type="text" :value="dialogText"/> -->
          <div class="d-flex">
            Podaj swoje dane kontaktowe. Dostaniesz linka z zapisanym nagrobkiem.
          </div>
<v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-text-field
      v-model="modalEmail"
      label="Adres e-mail"
      :rules="modalEmailRules"
      required
    ></v-text-field>

    <v-text-field
      v-model="modalPhone"
      label="Numer telefonu"
      :rules="modalPhoneRules"
      required
    ></v-text-field>

    <v-text-field
      v-model="modalSize"
      label="Rozmiar nagrobka / cmentarz"
      :rules="modalSizeRules"
      required
    ></v-text-field>

    <v-select
      v-model="modalType"
      label="Rodzaj grobu"
      :items="['murowany', 'ziemny']"
      :rules="modalTypeRules"
      required
    ></v-select>

    <v-select
      v-model="modalExists"
      label="Jest istniejący nagrobek?"
      :items="['tak', 'nie']"
      :rules="modalExistsRules"
      required
    ></v-select>    

    <!-- <v-checkbox
      v-model="checkbox"
      :rules="[v => !!v || 'Zaakceptuj politykę prywatności!']"
      required
    > <div slot='label'>Akceptuję <a target='_blank' href='https://gravecreator.pl/polityka-prywatnosci/'>politykę prywatności</a></div>
  </v-checkbox> -->

  <p class="text-caption">{{ this.configRodoText1 }} <a v-if="changeRodoMessageVisible" href="#" @click="changeRodoMessage">więcej</a>
  </p>

    <!-- <v-btn
      :disabled="!valid"
      color="success"
      class="mr-4"
      @click="validate"
    >
      Zapisz
    </v-btn>         -->

    <v-btn
      color="success"
      class="mr-4"
      @click="validateForm()"
      :loading="saving3"
    >
      Zapisz
    </v-btn>        

</v-form>              
        </v-card-text>

        <!-- <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="dialog3 = false"
          >
            Zapisz
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          link
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Twój link
        </v-card-title>

        <v-card-text>
          <!-- <input id="html_dialog_text" type="text" :value="dialogText"/> -->
          <div class="d-flex">
            <v-input v-model="dialogText">{{ dialogText }}</v-input>          
            <v-btn @click="copy_to_clipboard">kopiuj</v-btn>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Zamknij
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<v-dialog
      v-model="dialogStart"
      width="500"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Witaj w aplikacji&nbsp;<nobr>GraveCreator</nobr>
        </v-card-title>

        <v-card-text>
          <v-img src="/assets/tooltip_image_menu.png"></v-img>

          <b>Na górze po lewej stronie</b> znajduje się menu w którym możesz wybrać rodzaj nagrobka, kolory granitów oraz wszystkie detale dotyczące nagrobka
          <b>Jeśli chcesz zapisać projekt</b> kliknij przycisk 'wyślij...'
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogStart = false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    

    <!-- <v-btn elevation="2" @click="load_model('102')">load 102</v-btn>
    <v-btn elevation="2" @click="load_model('107')">load 107</v-btn>
    <v-btn elevation="2" @click="load_model('123')">load 123</v-btn> -->

    <!-- <v-btn elevation="2" @click="wycena()">wycena</v-btn> -->


    <v-dialog
      v-model="dialogTimeOut"
      persistent
      width="500"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          Aby kontynuować, wprowadź kod
        </v-card-title>

        <v-card-text>
          <div class="d-flex">
            Wpisz kod do aplikacji otrzymany od kamieniarza:
          </div>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >

          <v-text-field
              v-model="modalPass"
              label="Kod"
            ></v-text-field>  
            
            <div class="d-flex">
            Jeśli nie masz kodu podaj swój numer telefonu, a za chwilę otrzymasz kod SMSem.
          </div>

            <v-text-field
              v-model="modalPhone"
              label="Numer telefonu"
              :rules="modalPhoneRules"
              required
            ></v-text-field>

            <!-- <v-checkbox
              v-model="checkbox"
              :rules="[v => !!v || 'Zaakceptuj politykę prywatności!']"
              required
            > <div slot='label'>Akceptuję <a target='_blank' href='https://gravecreator.pl/polityka-prywatnosci/'>politykę prywatności</a></div>
          </v-checkbox> -->

  <p class="text-caption">{{ this.configRodoText1 }} <a v-if="changeRodoMessageVisible" href="#" @click="changeRodoMessage">więcej</a>
  </p>

    <!-- <v-btn
      :disabled="!valid"
      color="success"
      class="mr-4"
      @click="validate"
    >
      Zapisz
    </v-btn>         -->

    <v-btn
      color="success"
      class="mr-4"
      @click="validateFormSendPass()"
    >
      Wyślij mi kod
    </v-btn>        

</v-form>                        
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogTimeOut = false"
          >
            ok
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>        
  </v-container>

  
<!--       
      <v-btn elevation="2" @click="model=1">podwójny</v-btn>
      <v-btn elevation="2" @click="model=2">pojedyńczy</v-btn>
      <v-btn elevation="2" @click="model=3">leżak</v-btn>
      <v-btn elevation="2" @click="model=4">kapliczka</v-btn>
      <v-btn elevation="2" @click="model=5">lezak scianka</v-btn>
      <v-btn elevation="2" @click="model=6">belkowy</v-btn>
      <v-btn elevation="2" @click="model=7">podwojny 2</v-btn> -->
      <!-- <v-textarea filled name="input-7-1"
          label="Inskrypcja"
          value=""
          v-model="modelTxt1" ></v-textarea> -->
      <!-- <v-btn elevation="2" @click="modelColour1=1">gray</v-btn>
      <v-btn elevation="2" @click="modelColour1=2">green</v-btn>
      <v-btn elevation="2" @click="modelColour1=3">red</v-btn>           -->

      <!-- <v-btn elevation="2" @click="modelTextColour1=1">black</v-btn>
      <v-btn elevation="2" @click="modelTextColour1=2">white</v-btn>
      <v-btn elevation="2" @click="modelTextColour1=3">gold</v-btn>         -->
    </v-navigation-drawer>
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid style="height: 100vh; width:100vw; padding:0" >
        <v-layout fill-height>
        <Model3d2 
          ref="model3d2"
          :modelId="modelId" 
          :modelData="modelData" 
          :modelShapesData="modelShapesData"
          :texturesData="texturesData"
          :modelTablica="modelTablica"
          :modelColours="modelColours"
          :modelTxtsColours="modelTxtsColours" 
          :modelTxtsFonts="modelTxtsFonts" 
          :modelTxtsFontsStyle="modelTxtsFontsStyle" 
          :modelTxts="modelTxts"
          :modelTxtsSize="modelTxtsSize"
          :modelShapes="modelShapes"
          :modelShapesColours="modelShapesColours"
          :modelShapesSize="modelShapesSize"
          :realSizeHeight="realSizeHeight"
          :realSizeWidth="realSizeWidth"
          :z_coords="z_coords"
          @wycena_api="wycena_api"
        />
        <div id="watermark"
        :style="{ backgroundImage: 'url(' + configWatermark + ')' }"
        >
          <!--v-img
            v-bind:src="configWatermark"
          ></v-img-->   
          </div>  
        </v-layout>
      </v-container>
    </v-main>    
    
  </v-app>
</template>

<script>
  import axios from 'axios';
  import Model3d2 from '@/components/Model3d2.vue';
  export default {
    components: {
      Model3d2,
    },
    data: () => ({
      saving: false,
      saving3: false,
      modalEmail: '',
      modalPhone: '',
      modalPass: '',
      modalSize: '',
      modalType: '',
      modalExists: '',
      modalEmailRules: [
        v => !!v || 'Adres email jest wymagany',
        v => /.+@.+\..+/.test(v) || 'Adres e-mail nie jest poprawny',
      ],
      modalPhoneRules: [
        v => !!v || 'Telefon jest wymagany',
        v => (v && v.length >= 9 && v.length <= 9 && /^[0-9]{9}$/.test(v)) || 'Telefon musi zawierać 9 cyfr',
      ],
      modalSizeRules:[v => !!v || 'Pole wymagane'],
      modalTypeRules:[v => !!v || 'Pole wymagane'],
      modalExistsRules:[v => !!v || 'Pole wymagane'],
      drawer: false,
      group: null,
      loaded: false,
      orderKey: null,
      userKey: null,
      showContactData: null, 
      modelId: '102',
      modelTablica: '1',
      // modelColour1: '4',
      // modelColour2: '1',
      // modelColour3: '1',
      // modelColours: ['4','2','1','2','4'],
      modelColours: [],
      // modelColours: ['3','1','0','1','3','0','0','0'],

      availableModels: [
        {id:"102", icon_path:'/assets/icons/102.png'}, 
        {id:"107", icon_path:'/assets/icons/107.png'}, 
        {id:"108", icon_path:'/assets/icons/108.png'}, 
        {id:"110", icon_path:'/assets/icons/110.png'}, 
        {id:"111", icon_path:'/assets/icons/111.png'}, 
        {id:"112", icon_path:'/assets/icons/112.png'}, 
        {id:"113", icon_path:'/assets/icons/113.png'}, 
        {id:"116", icon_path:'/assets/icons/116.png'}, 
        {id:"117", icon_path:'/assets/icons/117.png'}, 
        {id:"118", icon_path:'/assets/icons/118.png'}, 
        {id:"120", icon_path:'/assets/icons/120.png'}, 
        {id:"121", icon_path:'/assets/icons/121.png'}, 
        {id:"122", icon_path:'/assets/icons/122.png'}, 
        {id:"123", icon_path:'/assets/icons/123.png'}, 

        {id:"124", icon_path:'/assets/icons/124.png'}, 
        {id:"125", icon_path:'/assets/icons/125.png'}, 
        {id:"126", icon_path:'/assets/icons/126.png'}, 
        {id:"127", icon_path:'/assets/icons/127.png'}, 
        {id:"128", icon_path:'/assets/icons/128.png'}, 
        {id:"129", icon_path:'/assets/icons/129.png'}, 
        {id:"130", icon_path:'/assets/icons/130.png'}, 
        {id:"131", icon_path:'/assets/icons/131.png'}, 
        {id:"132", icon_path:'/assets/icons/132.png'}, 
        {id:"133", icon_path:'/assets/icons/133.png'}, 

        {id:"134", icon_path:'/assets/icons/134.png'}, 
        {id:"135", icon_path:'/assets/icons/135.png'}, 
        {id:"136", icon_path:'/assets/icons/136.png'}, 
        {id:"137", icon_path:'/assets/icons/137.png'}, 
        {id:"138", icon_path:'/assets/icons/138.png'}, 
        {id:"139", icon_path:'/assets/icons/139.png'}, 
        {id:"140", icon_path:'/assets/icons/140.png'}, 
        {id:"141", icon_path:'/assets/icons/141.png'}, 
        {id:"142", icon_path:'/assets/icons/142.png'}, 
        {id:"143", icon_path:'/assets/icons/143.png'}, 
        {id:"144", icon_path:'/assets/icons/144.png'}, 
        
        {id:"145", icon_path:'/assets/icons/145.png'}, 
        {id:"146", icon_path:'/assets/icons/146.png'}, 
        {id:"147", icon_path:'/assets/icons/147.png'}, 
        {id:"148", icon_path:'/assets/icons/148.png'}, 
        {id:"149", icon_path:'/assets/icons/149.png'}, 
        {id:"150", icon_path:'/assets/icons/150.png'}, 
        {id:"151", icon_path:'/assets/icons/151.png'}, 
        {id:"152", icon_path:'/assets/icons/152.png'}, 
        {id:"153", icon_path:'/assets/icons/153.png'}, 
        {id:"154", icon_path:'/assets/icons/154.png'},         

        {id:"155", icon_path:'/assets/icons/155.png'}, 
        {id:"156", icon_path:'/assets/icons/156.png'}, 
        {id:"157", icon_path:'/assets/icons/157.png'}, 
        {id:"158", icon_path:'/assets/icons/158.png'}, 
        {id:"159", icon_path:'/assets/icons/159.png'}, 
        {id:"160", icon_path:'/assets/icons/160.png'}, 
        {id:"161", icon_path:'/assets/icons/161.png'}, 
        {id:"162", icon_path:'/assets/icons/162.png'}, 
        {id:"163", icon_path:'/assets/icons/163.png'}, 
        {id:"164", icon_path:'/assets/icons/164.png'},         

        {id:"165", icon_path:'/assets/icons/165.png'}, 
        {id:"166", icon_path:'/assets/icons/166.png'}, 
        {id:"167", icon_path:'/assets/icons/167.png'}, 
        {id:"168", icon_path:'/assets/icons/168.png'}, 
        {id:"169", icon_path:'/assets/icons/169.png'}, 
        {id:"170", icon_path:'/assets/icons/170.png'}, 
        {id:"171", icon_path:'/assets/icons/171.png'}, 
        {id:"172", icon_path:'/assets/icons/172.png'}, 
        {id:"173", icon_path:'/assets/icons/173.png'}, 
        {id:"174", icon_path:'/assets/icons/174.png'},             
        {id:"175", icon_path:'/assets/icons/175.png'},             
        {id:"176", icon_path:'/assets/icons/176.png'},             
        {id:"177", icon_path:'/assets/icons/177.png'},             

        {id:"5001", icon_path:'/assets/icons/5001.png'}, 
        {id:"5002", icon_path:'/assets/icons/5002.png'}, 
        {id:"5003", icon_path:'/assets/icons/5003.png'}, 
        {id:"5004", icon_path:'/assets/icons/5004.png'}, 
        {id:"5005", icon_path:'/assets/icons/5005.png'}, 
        {id:"5006", icon_path:'/assets/icons/5006.png'}, 
        {id:"5007", icon_path:'/assets/icons/5007.png'}, 
        {id:"5008", icon_path:'/assets/icons/5008.png'}, 
        {id:"5009", icon_path:'/assets/icons/5009.png'},         
        {id:"5010", icon_path:'/assets/icons/5010.png'},                                                                 
        
        {id:"5011", icon_path:'/assets/icons/5011.png'}, 
        {id:"5012", icon_path:'/assets/icons/5012.png'}, 
        {id:"5013", icon_path:'/assets/icons/5013.png'}, 
        {id:"5014", icon_path:'/assets/icons/5014.png'}, 
        {id:"5015", icon_path:'/assets/icons/5015.png'}, 
        {id:"5016", icon_path:'/assets/icons/5016.png'}, 
        {id:"5017", icon_path:'/assets/icons/5017.png'}, 
        {id:"5018", icon_path:'/assets/icons/5018.png'}, 
        {id:"5019", icon_path:'/assets/icons/5019.png'},         
        {id:"5020", icon_path:'/assets/icons/5020.png'},                                                                 
        {id:"5021", icon_path:'/assets/icons/5021.png'},                                                                 
        {id:"5022", icon_path:'/assets/icons/5022.png'},          
        {id:"5023", icon_path:'/assets/icons/5023.png'},          
        {id:"5024", icon_path:'/assets/icons/5024.png'},          
        {id:"5025", icon_path:'/assets/icons/5025.png'},          
        {id:"5026", icon_path:'/assets/icons/5026.png'},          
        {id:"5027", icon_path:'/assets/icons/5027.png'},                                                          

      ],
      modelData: [],
      modelData3: [
        {id: 0, path: '/assets/pomnik_podwojny_240_250_2.gltf',
          txtPositions: [
            {x:-1+0.25, y:0.2, z:1.028},
            {x:-1+0.25, y:0.1, z:1.028},
            {x:-1+0.25, y:-0.2+0.2, z:1.028}
          ],
          objPositions: [
            {x:-0.3, y:0.1, z:1.028}
          ],
          elements: [
            {id:0, name: 'krzyz', colour: '4'},
            {id:1, name: 'tablica_l', colour: '1'},
            {id:2, name: 'tablica_p', colour: '1'},
            {id:3, name: 'plyta', colour: '2'},
            {id:4, name: 'skrzynia', colour: '3'},
            {id:5, name: 'podstawa', colour: '4'},
          ]
        },
        {id: 1, path: '/assets/nagrobek_120_250_4.gltf',
          txtPositions: [
            {x: -0.15,y: 0.4,z: 1.028},
            {x: -0.15,y: 0.3,z: 1.028},
            {x: -0.15,y: 0.2,z: 1.028}
          ],
          objPositions: [
            {x:0.1, y:0.1, z:1.028}
          ],
          elements: [
            {id:0, name: 'tablica', colour: '1'},
            {id:1, name: 'plyta', colour: '2'},
            {id:2, name: 'skrzynia', colour: '3'},
            {id:3, name: 'podstawa', colour: '4'},
          ]
        },
      ],
      modelData2: [
        {id: 0, path: '/assets/pomnik_podwojny_240_250_2.gltf', 
          // txtPosition: {x:-1, y:0.1, z:-1.128},
          txtPositions: [
            {x:-1+0.25, y:0.2, z:-1.128},
            {x:-1+0.25, y:0.1, z:-1.128},
            {x:-1+0.25, y:-0.2+0.2, z:-1.128}
          ],
          objPositions: [
            {x:-0.3, y:0.1, z:-1.128}
          ],
          elements: [
            {id:0, name: 'krzyz', colour: '4'},
            {id:1, name: 'tablica_l', colour: '1'},
            {id:2, name: 'tablica_p', colour: '1'},
            {id:3, name: 'plyta', colour: '2'},
            {id:4, name: 'skrzynia', colour: '3'},
            {id:5, name: 'podstawa', colour: '4'},
          ]
        },
        {id: 1, path: '/assets/nagrobek_120_250_4.gltf', 
          txtPositions: [
            {x:-0.15, y:0.4, z:-1.128},
            {x:-0.15, y:0.3, z:-1.128},
            {x:-0.15, y:0.2, z:-1.128}
          ],
          objPositions: [{x:0.3, y:0.3, z:-1.128}],
          elements: [
            {id:0, name: 'tablica', colour: '1'},
            {id:1, name: 'plyta', colour: '2'},
            {id:2, name: 'skrzynia', colour: '3'},
            {id:3, name: 'podstawa', colour: '4'},
          ]
        },          
        {id: 2, path: '/assets/pomnik_lezak_2.gltf', 
          txtPositions: [
            {x:-0.4, y:0, z:-1.128},
            {x:-0.4, y:0.1, z:-1.128},
            {x:-0.4, y:0.2, z:-1.128}
          ],
          objPositions: [
            {x:-0.3, y:0.1, z:-1.128}
          ],
          elements: [
            {id:0, name: 'kolumna_1', colour: '4'},
            {id:1, name: 'kolumna_2', colour: '4'},
            {id:2, name: 'kolumna_3', colour: '4'},
            {id:3, name: 'kolumna_4', colour: '4'},
            {id:4, name: 'kolumna_5', colour: '4'},
            {id:5, name: 'kolumna_6', colour: '4'},
            {id:6, name: 'kolumna_7', colour: '4'},
            {id:7, name: 'kolumna_8', colour: '4'},
            {id:8, name: 'tablica', colour: '1'},
            {id:10, name: 'plyta', colour: '2'},
          ]          
        },
        {id: 3, path: '/assets/model_kapliczka_2.gltf', 
          txtPositions: [
            {x:-0.4, y:0, z:-1.128},
            {x:-0.4, y:0.1, z:-1.128},
            {x:-0.4, y:0.2, z:-1.128}
          ],
          objPositions: [
            {x:-0.3, y:0.1, z:-1.128}
          ],
          elements: [
            {id:0, name: 'kolumna_1', colour: '4'},
            {id:1, name: 'kolumna_2', colour: '4'},
            {id:2, name: 'kolumna_3', colour: '4'},
            {id:3, name: 'kolumna_4', colour: '4'},
            {id:4, name: 'kolumna_5', colour: '4'},
            {id:5, name: 'kolumna_6', colour: '4'},
            {id:6, name: 'kolumna_7', colour: '4'},
            {id:7, name: 'kolumna_8', colour: '4'},
            {id:8, name: 'tablica', colour: '1'},
            {id:10, name: 'plyta', colour: '2'},
          ]               
          },
        {id: 4, path: '/assets/lezak_scianka_2.gltf', 
          txtPositions: [{x:-0.4, y:0.2, z:-1.1}],
          objPositions: [{x:0.3, y:0.3, z:-1.1}],
          },
        {id: 5, path: '/assets/piaskowiec_belkowy_2.gltf', 
          txtPositions: [{x:-0.4, y:0.2, z:-1.1}],
          objPositions: [{x:0.3, y:0.3, z:-1.1}],
          },
        {id: 6, path: '/assets/podwojny_z_wjazdem_po_srodku_2.gltf', 
          txtPositions: [{x:-0.9, y:0.3, z:-1.1}],
          objPositions: [{x:0.8, y:0.3, z:-1.1}],
          },
        {id: 7, path: '/assets/podcien_5.gltf', 
          txtPositions: [
            {x:-0.15, y:0.4, z:-1.128},
            {x:-0.15, y:0.3, z:-1.128},
            {x:-0.15, y:0.2, z:-1.128}
          ],
          objPositions: [{x:0.15, y:0.0, z:-1.128}],
          elements: 
          [
            {id:0, name: 'tablica', colour: '1'},
            {id:1, name: 'plyta', colour: '1'},
            {id:2, name: 'podcien', colour: '1'},
            {id:3, name: 'skrzynia', colour: '1'},
            {id:4, name: 'podstawa', colour: '1'},
          ]
        },                 
        {id: 8, path: '/assets/Nagrobek_21122021_V2_mb.gltf', 
          txtPositions: [
            {x:-0.15, y:0.4, z:-1.128},
            {x:-0.15, y:0.3, z:-1.128},
            {x:-0.15, y:0.2, z:-1.128}
          ],
          objPositions: [{x:0.3, y:0.3, z:-1.128}],
          elements: [
            {id:0, name: 'Tablica', colour: '1'},
            {id:1, name: 'Plyta', colour: '2'},
            {id:2, name: 'Plyta_Rog', colour: '2'},
            {id:3, name: 'Skrzynia', colour: '3'},
            {id:4, name: 'Podstawa', colour: '4'},
          ]
        },                    

        {id: 9, path: '/assets/20210606_142421.gltf', 
          txtPositions: [
            {x:-0.15, y:0.4, z:-1.128},
            {x:-0.15, y:0.3, z:-1.128},
            {x:-0.15, y:0.2, z:-1.128}
          ],
          objPositions: [{x:0.3, y:0.3, z:-1.128}],
          elements: [
            {id:0, name: 'Krzyz', colour: '1'},
            {id:1, name: 'Krzyz_M', colour: '2'},
            {id:2, name: 'Tablica', colour: '2'},
            {id:3, name: 'Plyta', colour: '3'},
            {id:4, name: 'Skrzynia', colour: '4'},
            {id:5, name: 'Podstawa', colour: '4'},
          ]
        },                    

        {id: 10, path: '/assets/20210610_1844121.gltf', 
          txtPositions: [
            {x:-0.15, y:0.4, z:-1.128},
            {x:-0.15, y:0.3, z:-1.128},
            {x:-0.15, y:0.2, z:-1.128}
          ],
          objPositions: [{x:0.3, y:0.3, z:-1.128}],
          elements: [
            {id:0, name: 'Krzyz', colour: '1'},
            {id:1, name: 'Tablica_L', colour: '2'},
            {id:2, name: 'Tablica_P', colour: '2'},
            {id:3, name: 'Tablica_S', colour: '2'},
            {id:4, name: 'Plyta', colour: '2'},
            {id:5, name: 'Skrzynia', colour: '3'},
            {id:6, name: 'Podstawa', colour: '4'},
          ]
        },                    

        {id: 11, path: '/assets/20210610_191641.gltf', 
          txtPositions: [
            {x:-0.15, y:0.4, z:-1.128},
            {x:-0.15, y:0.3, z:-1.128},
            {x:-0.15, y:0.2, z:-1.128}
          ],
          objPositions: [{x:0.3, y:0.3, z:-1.128}],
          elements: [
            {id:0, name: 'Krzyz', colour: '1'},
            {id:1, name: 'Tablica_L', colour: '2'},
            {id:2, name: 'Tablica_P', colour: '2'},
            {id:3, name: 'Plyta', colour: '2'},
            {id:4, name: 'Skrzynia', colour: '3'},
            {id:5, name: 'Podstawa', colour: '4'},
          ]
        },                    

        {id: 12, path: '/assets/20210610_1845251.gltf', 
          txtPositions: [
            {x:-0.15, y:0.4, z:-1.128},
            {x:-0.15, y:0.3, z:-1.128},
            {x:-0.15, y:0.2, z:-1.128}
          ],
          objPositions: [{x:0.3, y:0.3, z:-1.128}],
          elements: [
            {id:0, name: 'Krzyz', colour: '1'},
            {id:1, name: 'Tablica_L', colour: '2'},
            {id:2, name: 'Tablica_P', colour: '2'},
            {id:3, name: 'Plyta', colour: '2'},
            {id:4, name: 'Podcien', colour: '2'},            
            {id:5, name: 'Skrzynia', colour: '3'},
            {id:6, name: 'Podstawa', colour: '4'},
          ]
        },   
        
        {id: 13, path: '/assets/20210610_191139.gltf', 
          txtPositions: [
            {x:-0.15, y:0.4, z:-1.128},
            {x:-0.15, y:0.3, z:-1.128},
            {x:-0.15, y:0.2, z:-1.128}
          ],
          objPositions: [{x:0.3, y:0.3, z:-1.128}],
          elements: [
            {id:0, name: 'Tablica_L', colour: '2'},
            {id:1, name: 'Tablica_P', colour: '2'},
            {id:2, name: 'Plyta', colour: '2'},
            {id:3, name: 'Skrzynia', colour: '3'},
            {id:4, name: 'Podstawa', colour: '4'},
          ]

        },                    
        

      ],

      modelShapesData: [
        {id: 0, name:'krzyż 01', img_path:'/assets/shapes/cross_01.png', svg_path: '/assets/shapes/cross_01.svg'},
        {id: 1, name:'krzyż 02', img_path:'/assets/shapes/cross_02.png', svg_path: '/assets/shapes/cross_02.svg'},
        {id: 2, name:'krzyż 03', img_path:'/assets/shapes/cross_03.png', svg_path: '/assets/shapes/cross_03.svg'},
        {id: 3, name:'krzyż 04', img_path:'/assets/shapes/cross_04.png', svg_path: '/assets/shapes/cross_04.svg'},
        {id: 4, name:'krzyż 05', img_path:'/assets/shapes/cross_05.png', svg_path: '/assets/shapes/cross_05.svg'},
        {id: 5, name:'krzyż 06', img_path:'/assets/shapes/cross_06.png', svg_path: '/assets/shapes/cross_06.svg'},
        {id: 6, name:'krzyż 07', img_path:'/assets/shapes/cross_07.png', svg_path: '/assets/shapes/cross_07.svg'},
        {id: 7, name:'krzyż 08', img_path:'/assets/shapes/cross_08.png', svg_path: '/assets/shapes/cross_08.svg'},
        {id: 8, name:'krzyż 09', img_path:'/assets/shapes/cross_09.png', svg_path: '/assets/shapes/cross_09.svg'},                
        {id: 9, name:'krzyż 10', img_path:'/assets/shapes/cross_10.png', svg_path: '/assets/shapes/cross_10.svg'},
        {id: 10, name:'krzyż 11', img_path:'/assets/shapes/cross_11.png', svg_path: '/assets/shapes/cross_11.svg'},
        {id: 11, name:'krzyż 12', img_path:'/assets/shapes/cross_12.png', svg_path: '/assets/shapes/cross_12.svg'},
        {id: 12, name:'krzyż 13', img_path:'/assets/shapes/cross_13.png', svg_path: '/assets/shapes/cross_13.svg'},
        {id: 13, name:'krzyż 14', img_path:'/assets/shapes/cross_14.png', svg_path: '/assets/shapes/cross_14.svg'},
        {id: 14, name:'krzyż 15', img_path:'/assets/shapes/cross_15.png', svg_path: '/assets/shapes/cross_15.svg'},                

        {id: 15, name:'krzyż 16', img_path:'/assets/shapes/cross_16.png', svg_path: '/assets/shapes/cross_16.svg'},                
        {id: 16, name:'krzyż 17', img_path:'/assets/shapes/cross_17.png', svg_path: '/assets/shapes/cross_17.svg'},                
        {id: 17, name:'krzyż 18', img_path:'/assets/shapes/cross_18.png', svg_path: '/assets/shapes/cross_18.svg'},                
        {id: 18, name:'krzyż 19', img_path:'/assets/shapes/cross_19.png', svg_path: '/assets/shapes/cross_19.svg'},                
        {id: 19, name:'krzyż 20', img_path:'/assets/shapes/cross_20.png', svg_path: '/assets/shapes/cross_20_h.svg'},                
        {id: 20, name:'krzyż 21', img_path:'/assets/shapes/cross_21.png', svg_path: '/assets/shapes/cross_21_h.svg'},                
        {id: 21, name:'krzyż 22', img_path:'/assets/shapes/cross_22.png', svg_path: '/assets/shapes/cross_22_h.svg'},                
        {id: 22, name:'krzyż 23', img_path:'/assets/shapes/cross_23.png', svg_path: '/assets/shapes/cross_23_h.svg'},                
        {id: 23, name:'krzyż 24', img_path:'/assets/shapes/cross_24.png', svg_path: '/assets/shapes/cross_24.svg'},                
        {id: 24, name:'krzyż 25', img_path:'/assets/shapes/cross_25.png', svg_path: '/assets/shapes/cross_25_h.svg'},                
        {id: 25, name:'krzyż 26', img_path:'/assets/shapes/cross_26.png', svg_path: '/assets/shapes/cross_26_h.svg'},                
        {id: 26, name:'krzyż 27', img_path:'/assets/shapes/cross_27.png', svg_path: '/assets/shapes/cross_27_h.svg'},                
        {id: 27, name:'krzyż 28', img_path:'/assets/shapes/cross_28.png', svg_path: '/assets/shapes/cross_28_h.svg'},                
        {id: 28, name:'krzyż 29', img_path:'/assets/shapes/cross_29.png', svg_path: '/assets/shapes/cross_29.svg'},                
        {id: 29, name:'krzyż 20', img_path:'/assets/shapes/cross_30.png', svg_path: '/assets/shapes/cross_30.svg'},                
        {id: 30, name:'krzyż 31', img_path:'/assets/shapes/cross_31.png', svg_path: '/assets/shapes/cross_31_h.svg'},                        
        {id: 31, name:'krzyż 32', img_path:'/assets/shapes/cross_32.png', svg_path: '/assets/shapes/cross_32_h.svg'},                
        {id: 32, name:'krzyż 33', img_path:'/assets/shapes/cross_33.png', svg_path: '/assets/shapes/cross_33_h.svg'},                
        {id: 33, name:'krzyż 34', img_path:'/assets/shapes/cross_34.png', svg_path: '/assets/shapes/cross_34_h.svg'},                
        {id: 34, name:'krzyż 35', img_path:'/assets/shapes/cross_35.png', svg_path: '/assets/shapes/cross_35_h.svg'},                

      ],

      texturesData: [
        {id: 0, value:'0', name:'Strzegom', price: '1000', path: '/assets/textures/granite_white_2048.jpg', icon_path: '/assets/textures/granite_white_512.jpg'},
        {id: 1, value:'1', name:'Olive Green RPA', price: '1300', path: '/assets/textures/granite_green_2048.jpg', icon_path: '/assets/textures/granite_green_512.jpg'},
        {id: 2, value:'2', name:'Eagle Red FI', price: '1300', path: '/assets/textures/granite_pink_2048.jpg', icon_path: '/assets/textures/granite_pink_512.jpg'},
        {id: 3, value:'3', name:'Labrador zielony', price: '2000', path: '/assets/textures/granite_black_2048.jpg', icon_path: '/assets/textures/granite_black_512.jpg'},
        {id: 4, value:'4', name:'Candeias BR', price: '2000', path: '/assets/textures/granite_green2_2048.jpg', icon_path: '/assets/textures/granite_green2_512.jpg'},
        {id: 5, value:'5', name:'Vanga Szwecja', price: '1300', path: '/assets/textures/granite_red3_2048.jpg', icon_path: '/assets/textures/granite_red3_512.jpg'},
        {id: 6, value:'6', name:'Balmoral Red', price: '1300', path: '/assets/textures/granite_red2_2048.jpg', icon_path: '/assets/textures/granite_red2_512.jpg'},
        {id: 7, value:'7', name:'Impala', price: '1000', path: '/assets/textures/granite_black2_2048.jpg', icon_path: '/assets/textures/granite_black2_512.jpg'},        
        {id: 8, value:'8', name:'Labrador Czarny', price: '1300', path: '/assets/textures/granite_black3_2048.jpg', icon_path: '/assets/textures/granite_black3_512.jpg'},
        {id: 9, value:'9', name:'Shivakashi', price: '2000', path: '/assets/textures/granite_yellow_2048.jpg', icon_path: '/assets/textures/granite_yellow_512.jpg'},
        {id: 10, value:'10', name:'Czarny Szwed', price: '2000', path: '/assets/textures/granite_sweden_black_2048.jpg', icon_path: '/assets/textures/granite_sweden_black_512.jpg'},
        {id: 11, value:'11', name:'Strzeblów Brown', price: '1300', path: '/assets/textures/granite_strzeblow_2048.jpg', icon_path: '/assets/textures/granite_strzeblow_512.jpg'},
        {id: 12, value:'12', name:'Brąz królewski', price: '1000', path: '/assets/textures/granite_g664_2048.jpg', icon_path: '/assets/textures/granite_g664_512.jpg'},
        {id: 13, value:'13', name:'Baltic Red', price: '1300', path: '/assets/textures/granite_baltic_2048.jpg', icon_path: '/assets/textures/granite_baltic_512.jpg'},
        {id: 14, value:'14', name:'Viscount White', price: '1300', path: '/assets/textures/granite_viscount_2048.jpg', icon_path: '/assets/textures/granite_viscount_512.jpg'},
        {id: 15, value:'15', name:'Indian Aurora', price: '1300', path: '/assets/textures/granite_indian_aurora_2048.jpg', icon_path: '/assets/textures/granite_indian_aurora_512.jpg'},
        {id: 16, value:'16', name:'Brazil Gold', price: '1300', path: '/assets/textures/granite_brazil_gold_2048.jpg', icon_path: '/assets/textures/granite_brazil_gold_512.jpg'},
        {id: 17, value:'17', name:'African', price: '1300', path: '/assets/textures/granite_african_2048.jpg', icon_path: '/assets/textures/granite_african_512.jpg'},        
        {id: 18, value:'18', name:'Orion', price: '1300', path: '/assets/textures/granite_orion_2048.jpg', icon_path: '/assets/textures/granite_orion_512.jpg'},
        {id: 19, value:'19', name:'Warangal black', price: '1300', path: '/assets/textures/granite_warangal_2048.jpg', icon_path: '/assets/textures/granite_warangal_512.jpg'},        
        {id: 20, value:'20', name:'Kuppam Green', price: '1300', path: '/assets/textures/granite_kuppam_green_2048.jpg', icon_path: '/assets/textures/granite_kuppam_green_512.jpg'},
        {id: 21, value:'21', name:'Bohus red', price: '1300', path: '/assets/textures/granite_bohus_red_2048.jpg', icon_path: '/assets/textures/granite_bohus_red_512.jpg'},        
        {id: 22, value:'22', name:'Blue moon', price: '1300', path: '/assets/textures/blue_moon_2048.jpg', icon_path: '/assets/textures/blue_moon_512.jpg'},
        {id: 23, value:'23', name:'Crema Julia', price: '1300', path: '/assets/textures/crema_julia_2048.jpg', icon_path: '/assets/textures/crema_julia_512.jpg'},
        {id: 24, value:'24', name:'Gnejs', price: '1300', path: '/assets/textures/gnejs_2048.jpg', icon_path: '/assets/textures/gnejs_512.jpg'},
        {id: 25, value:'25', name:'Hassan green', price: '1300', path: '/assets/textures/hassan_green_2048.jpg', icon_path: '/assets/textures/hassan_green_512.jpg'},
        {id: 26, value:'26', name:'Imperial red', price: '1300', path: '/assets/textures/imperial_red_2048.jpg', icon_path: '/assets/textures/imperial_red_512.jpg'},
        {id: 27, value:'27', name:'imperial white', price: '1300', path: '/assets/textures/imperial_white_2048.jpg', icon_path: '/assets/textures/imperial_white_512.jpg'},        
        {id: 28, value:'28', name:'Labrador blue', price: '1300', path: '/assets/textures/labrador_blue_2048.jpg', icon_path: '/assets/textures/labrador_blue_512.jpg'},
        {id: 29, value:'29', name:'Perfekt white', price: '1300', path: '/assets/textures/perfekt_white_2048.jpg', icon_path: '/assets/textures/perfekt_white_512.jpg'},        
        {id: 30, value:'30', name:'Tan Brown', price: '1300', path: '/assets/textures/tan_brown_2048.jpg', icon_path: '/assets/textures/tan_brown_512.jpg'},
        {id: 31, value:'31', name:'Tarn Francja', price: '1300', path: '/assets/textures/tarn_francja_2048.jpg', icon_path: '/assets/textures/tarn_francja_512.jpg'},        
        
      ],

      realSizeHeights: ['180','190','200','210','220','230','240','250'],
      realSizeWidths: ['80','90','100','110','120','130','140','150','160','170','180','190','200','210','220','230','240','250'],
      realSizeHeight: '190',
      realSizeWidth: '90',

      // modelTxts: ['śp', 'Jan Nowak','1900 - 1980'],
      // modelTxtsColours: ['2','2','2'],
      // modelTxtsSize: [4,8,4],

      modelTxts: [],
      modelTxtsColours: [],
      modelTxtsFonts: [],
      modelTxtsFontsStyle: [],
      modelTxtsSize: [],
      
      // modelShapes: ['0'],
      // modelShapesColours: ['2'],
      // modelShapesSize: [10]

      modelShapes: [],
      modelShapesColours: [],
      modelShapesSize: [],

      price_visibility: true,
      price_val: 0,
      price_desc : '',
      price_customer_phone : '',

      dialog: false,
      dialog2: false,
      dialog3: false,
      dialogText: '',
      dialogStart: false,
      dialogTimeOut: false,
      valid: false,

      z_coords: false,

      changeRodoMessageVisible: true,

      configLogo: 'https://app2.gravecreator.pl/assets/logos/gravecreator_logo_268x50.png',
      configWatermark: 'https://app2.gravecreator.pl/assets/logos/gravecreator_watermark.png',
      configPass: '1111',
      configPassOneTime: '2222',
      configRodoText1: 'Administratorem danych, które tu wpisujesz będziemy My, czyli: GraveCreator sp. z o.o. Dane będą przetwarzane w celu marketingu bezpośredniego naszych produktów i usług. Podstawą prawną przetwarzania jest uzasadniony interes Administratora.',
      configRodoText2: 'Administratorem danych, które tu wpisujesz będziemy My, czyli: GraveCreator sp. z o.o. Dane będą przetwarzane w celu marketingu bezpośredniego naszych produktów i usług. Podstawą prawną przetwarzania jest uzasadniony interes Administratora. Masz m.in. prawo do żądania dostępu do danych, sprostowania, usunięcia oraz zgłoszenia sprzeciwu na marketing produktów i usług Administratora, jak również skorzystać z innych praw opisanych szczegółowo w polityce prywatności. Odbiorcami Twoich danych czyli podmiotami, którym będziemy mogli przekazać Twoje dane zgodnie z obowiązującym prawem będą podmioty pomagające Nam w działaniach związanych z opisanym wyżej marketingiem bezpośrednim produktów i usług własnych oraz inne podmioty uprawnione do uzyskania danych na podstawie obowiązującego prawa.',

    }),
    methods: {

      onModelsExpansionPanelClick() {
        this.$refs.model3d2.setCameraOnObject()
      },

      onTablesExpansionPanelClick() {
        this.$refs.model3d2.setCameraOnObject()      
      },

      onColorsExpansionPanelClick() {
        this.$refs.model3d2.setCameraOnObject()      
      },

      onTextsExpansionPanelClick(event) {
        if(event.target.classList.contains('v-expansion-panel-header--active')) {
          this.$refs.model3d2.setCameraOnObject()
        } else {
          this.$refs.model3d2.setCameraOnTable()
        }
      },
     
      onShapesExpansionPanelClick(event) {
        if(event.target.classList.contains('v-expansion-panel-header--active')) {
          this.$refs.model3d2.setCameraOnObject()
        } else {
          this.$refs.model3d2.setCameraOnTable()
        }
      },

      add_text: function() {
        this.modelTxts.push('inskrypcja '+(this.modelTxts.length+1));
        this.modelTxtsSize.push(24);
        this.modelTxtsColours.push('2');
        this.modelTxtsFonts.push('1');
        this.modelTxtsFontsStyle.push('1');
      },

      remove_text: function() {
        console.log('remove txt');
      },

      add_shape: function() {
        this.modelShapes.push('1');
        // this.modelTxtsSize.push(4);
        this.modelShapesColours.push('2');
        this.modelShapesSize.push(80);
      },

      remove_shape: function(index) {
        console.log('remove_txt')
        // console.log(index)
        this.modelShapes.splice(index,1)
        this.modelShapesColours.splice(index,1)
        this.modelShapesSize.splice(index,1)
        // console.log(this.modelShapes)
        this.save_and_actualise_price(index)
      },      

      load_all_models: function(currentModelId) {

        // console.log('start axios')
        axios.get('/data2.json')
          .then(response => {
            this.modelData = response.data.models
            // console.log(this.modelData[this.modelId])
            
            //populate elements colours
            for(let elId of [...Array(this.modelData[currentModelId].elements.length).keys()]) {
              this.modelColours.push(this.modelData[currentModelId].elements[elId].colour)
            }

            //populate texts, colours, sizes
            for(let elId of [...Array(this.modelData[currentModelId].txtPositions.length).keys()]) {
              // 
              this.modelTxts.push(this.modelData[currentModelId].txtPositions[elId].content)
              this.modelTxtsColours.push(this.modelData[currentModelId].txtPositions[elId].colour)
              this.modelTxtsFonts.push(this.modelData[currentModelId].txtPositions[elId].font)
              this.modelTxtsFontsStyle.push(this.modelData[currentModelId].txtPositions[elId].font_style)
              this.modelTxtsSize.push(this.modelData[currentModelId].txtPositions[elId].size)
            }

            //populate shapes, colours, sizes
            for(let elId of [...Array(this.modelData[currentModelId].objPositions.length).keys()]) {
              // 
              this.modelShapes.push(this.modelData[currentModelId].objPositions[elId].object_id)
              this.modelShapesColours.push(this.modelData[currentModelId].objPositions[elId].colour)
              this.modelShapesSize.push(this.modelData[currentModelId].objPositions[elId].size)
            }

            return true
          })
          .then(status => {
            this.loaded = status
            this.$refs.model3d2.startApp()
            this.wycena_api()
            }
        ); 

      },

      async load_config_data(pModelId) {
        console.log(pModelId)
        const req = axios.get(this.$API_URL+'/api/?method=load_config_data&model_id='+pModelId)
        req instanceof Promise
        await req

        req
          .then(response => {
            // console.log('logo_url: '+response.data.logo_url)
            // console.log('rodo_text1: '+response.data.rodo_text1)
            // console.log('rodo_text2: '+response.data.rodo_text2)
            if(response.data.logo_url) this.configLogo = this.$API_URL+'/assets/logos/'+response.data.logo_url
            if(response.data.watermark_url) this.configWatermark = this.$API_URL+'/assets/logos/'+response.data.watermark_url
            if(response.data.pass) {
              this.configPass = response.data.pass.substr(1,4)
              this.configPassOneTime = response.data.pass.substr(5,4)
            }
            if(response.data.rodo_text1) this.configRodoText1 = response.data.rodo_text1
            if(response.data.rodo_text2) this.configRodoText2 = response.data.rodo_text2
            if(response.data.models) {
              let models = response.data.models.split(",");
              models.forEach(id => {
                  let model = this.availableModels.find(model => model.id === id);
                  if (model) {
                      model.enable = "1";
                  }
              });
            }
            if(response.data.stones) {
              let stones = response.data.stones.split(",");
              stones.forEach(id => {
                  let stone = this.texturesData.find(model => model.value === id);
                  if (stone) {
                    stone.enable = "1";
                  }
              });
            }
            return true
          })
          ;         

      },

      async load_model_desc(pModelId, pModelDescId) {

        let newId

        const req = axios.get(this.$API_URL+'/api/?method=load_model_desc&model_id='+pModelId+'&model_desc_id='+pModelDescId)
        req instanceof Promise
        await req
        
        // axios.get('/api/data_'+pModelId+'.json')
        // axios.get(this.$API_URL+'/api/?method=load_model_desc&model_id='+pModelId+'&model_desc_id='+pModelDescId)
        req
          .then(response => {
            // this.modelData[this.modelId] = null
            newId = response.data.models[0].parent_id
            this.modelDescId = pModelId
            this.modelId = newId
            this.modelData[newId] = response.data.models[0]
            this.realSizeHeight = response.data.models[0].height || '190'
            this.realSizeWidth = response.data.models[0].width || '90'
            //podwojny
            if(this.realSizeWidth=='90' && response.data.models[0].parent_id>'5000' && response.data.models[0].parent_id<'6000') {
              this.realSizeWidth = '180'
            }
            // console.log('newId: '+newId)
            return true
          })

          .then(status => {


            // console.log('***********')
            // console.log(this.modelData)
            // console.log(this.modelData[newId])
            // console.log(this.modelData[this.modelId].path)

            this.modelColours = []
            this.modelTxts = []
            this.modelTxtsColours = []
            this.modelTxtsFonts = []
            this.modelTxtsFontsStyle = []
            this.modelTxtsSize = []
            this.modelShapes = []
            this.modelShapesColours = []
            this.modelShapesSize = []            
            
            //populate elements colours
            for(let elId of [...Array(this.modelData[newId].elements.length).keys()]) {
              this.modelColours.push(this.modelData[newId].elements[elId].colour)
            }

            //populate texts, colours, sizes
            for(let elId of [...Array(this.modelData[newId].txtPositions.length).keys()]) {
              // 
              this.modelTxts.push(this.modelData[newId].txtPositions[elId].content)
              this.modelTxtsColours.push(this.modelData[newId].txtPositions[elId].colour.toString())
              this.modelTxtsFonts.push(this.modelData[newId].txtPositions[elId].font.toString())
              this.modelTxtsFontsStyle.push(this.modelData[newId].txtPositions[elId].font_style.toString())
              this.modelTxtsSize.push(this.modelData[newId].txtPositions[elId].size)
            }

            //populate shapes, colours, sizes
            for(let elId of [...Array(this.modelData[newId].objPositions.length).keys()]) {
              //moze byc objPositions tylko z samymi [x,y,z]
              if(this.modelData[newId].objPositions[elId].object_id) {
                this.modelShapes.push(this.modelData[newId].objPositions[elId].object_id)
                this.modelShapesColours.push(this.modelData[newId].objPositions[elId].colour.toString())
                this.modelShapesSize.push(this.modelData[newId].objPositions[elId].size)
              }
            }

            return status

          })

          .then(status => {
            this.loaded = status
            this.modelId = newId
            // console.log('****new modelId:')
            // console.log(this.modelId)
            
            this.wycena_api()
          })
          ; 

      },

      load_saved_model_del: function(gcModelId) {
        let currentModelId = this.modelId

        axios.get('/data'+gcModelId+'.json')
          .then(response => {

            this.modelData = response.data.models
            // console.log(this.modelData[this.modelId])

            this.modelColours = []
            this.modelTxts = []
            this.modelTxtsColours = []
            this.modelTxtsSize = []
            this.modelShapes = []
            this.modelShapesColours = []
            this.modelShapesSize = []
            
            //populate elements colours
            for(let elId of [...Array(this.modelData[currentModelId].elements.length).keys()]) {
              this.modelColours.push(this.modelData[currentModelId].elements[elId].colour)
            }

            //populate texts, colours, sizes
            for(let elId of [...Array(this.modelData[currentModelId].txtPositions.length).keys()]) {
              // 
              this.modelTxts.push(this.modelData[currentModelId].txtPositions[elId].content)
              this.modelTxtsColours.push(this.modelData[currentModelId].txtPositions[elId].colour)
              this.modelTxtsSize.push(this.modelData[currentModelId].txtPositions[elId].size)
            }

            //populate shapes, colours, sizes
            for(let elId of [...Array(this.modelData[currentModelId].objPositions.length).keys()]) {
              // 
              this.modelShapes.push(this.modelData[currentModelId].objPositions[elId].object_id)
              this.modelShapesColours.push(this.modelData[currentModelId].objPositions[elId].colour)
              this.modelShapesSize.push(this.modelData[currentModelId].objPositions[elId].size)
            }            
            // console.log(this.modelData[this.modelId])
            // this.modelData[this.modelId] = response.data.models
            // console.log(currentModelId, gcModelId)
            // console.log(this.modelData[this.modelId])
            
            // //populate elements colours
            // for(let elId of [...Array(this.modelData[currentModelId].elements.length).keys()]) {
            //   this.modelColours.push(this.modelData[currentModelId].elements[elId].colour)
            // }

            // //populate texts, colours, sizes
            // for(let elId of [...Array(this.modelData[currentModelId].txtPositions.length).keys()]) {
            //   // 
            //   this.modelTxts.push(this.modelData[currentModelId].txtPositions[elId].content)
            //   this.modelTxtsColours.push(this.modelData[currentModelId].txtPositions[elId].colour)
            //   this.modelTxtsSize.push(this.modelData[currentModelId].txtPositions[elId].size)
            // }

            // //populate shapes, colours, sizes
            // for(let elId of [...Array(this.modelData[currentModelId].objPositions.length).keys()]) {
            //   // 
            //   this.modelShapes.push(this.modelData[currentModelId].objPositions[elId].object_id)
            //   this.modelShapesColours.push(this.modelData[currentModelId].objPositions[elId].colour)
            //   this.modelShapesSize.push(this.modelData[currentModelId].objPositions[elId].size)
            // }

            return true
          })
          .then(status => {
            this.loaded = status
            this.modelIdOld = null
            this.modelTxtsOld = null
            // this.$refs.model3d2.startApp()
            }
        );         
      },

      //deprecated na rzecz wycena_api po stronie backendu
      wycena() {

        let tmpPriceAll = 0
        let tmpPriceAllDesc = ''

        let currentModelId = this.modelId

        //tablica

        //nagrobek
        for(let elId of [...Array(this.modelData[currentModelId].elements.length).keys()]) {
          let elementSize = this.modelData[currentModelId].elements[elId].size
          let elementName = this.modelData[currentModelId].elements[elId].name
          // let elementStone = this.modelData[currentModelId].elements[elId].colour

          let meshId = this.modelData[currentModelId].elements.find(x => x.name === elementName).id;
          let elementStone = this.modelColours[meshId];
          // console.log(elementStone)

          let stonePrice = this.texturesData[elementStone].price

          let tmpPrice1 = Math.round(elementSize * stonePrice)
          tmpPriceAll += tmpPrice1

          tmpPriceAllDesc += elementName + ": "+ elementSize + "m2 x " + stonePrice + "zł/m2 = " + tmpPrice1 + " zł" + "\n"
          // 
          // this.modelShapes.push(this.modelData[currentModelId].objPositions[elId].object_id)
          // this.modelShapesColours.push(this.modelData[currentModelId].objPositions[elId].colour)
          // this.modelShapesSize.push(this.modelData[currentModelId].objPositions[elId].size)

        }          

        //shape

        //tekst
        let ileZnakow = 0
        for(let elId of [...Array(this.modelTxts.length).keys()]) {
          ileZnakow += this.modelTxts[elId].length
        }

        let tmpPriceZ = Math.round(ileZnakow*5)
        tmpPriceAll += tmpPriceZ
        tmpPriceAllDesc += "napisy: " + ileZnakow + " x 5 zł = " + tmpPriceZ + " zł"

        this.price_val = tmpPriceAll + ' zł';
        this.price_desc = tmpPriceAllDesc;

      },

      async wycena_api() {

        const req = axios.get(this.$API_URL+'/api/?method=get_price&model_id='+this.modelId+'&model_desc_id='+this.modelDescId+'&order_key='+this.orderKey+'&size_height='+this.realSizeHeight+'&size_width='+this.realSizeWidth)
        req instanceof Promise
        await req    
        
        req
          .then(response => {
            this.price_val = response.data.price_val;
            this.price_desc = response.data.price_desc;
            this.price_customer_phone = response.data.price_customer_phone;
            return true
          })  
          ;   
      },

      save_and_actualise_price(index) {

        //get json
        let json_content = JSON.stringify(this.$refs.model3d2.get_model_json(index))
        let parsedId

        this.modelDescId = this.$route.query.show

        axios.post(this.$API_URL+'/api/', {method: 'save_model_desc', make_screenshot: '0', model_id: this.modelId, model_desc_id: this.modelDescId, order_key:this.orderKey, json: json_content}, {
          headers: {
            // 'Content-Type': 'application/json' //this enable CORS (OPTIONS+POST) !
            'Content-Type': 'text/plain' 
          }
        })
        .then(response => {
          //parse id
          parsedId = response.data
          console.log(parsedId)
          this.wycena_api()
        })
      },
      
      save_and_show_link() {
        //get json
        let json_content = JSON.stringify(this.$refs.model3d2.get_model_json())
        let parsedId

        this.modelDescId = this.$route.query.show

        axios.post(this.$API_URL+'/api/', {method: 'save_model_desc', make_screenshot: '0', model_id: this.modelId, model_desc_id: this.modelDescId, order_key:this.orderKey, json: json_content}, {
          headers: {
            // 'Content-Type': 'application/json' //this enable CORS (OPTIONS+POST) !
            'Content-Type': 'text/plain' 
          }
        })
        .then(response => {
          //parse id
          parsedId = response.data

          //show id
          this.dialog = true
          this.dialogText = this.$API_URL+'/index.html?show='+parsedId            

        })
      },

      save_and_close_window() {
        //get json
        let json_content = JSON.stringify(this.$refs.model3d2.get_model_json())
        let parsedId

        this.modelDescId = this.$route.query.show

        axios.post(this.$API_URL+'/api/', {method: 'save_model_desc', make_screenshot: '0', model_id: this.modelId, model_desc_id: this.modelDescId, order_key:this.orderKey, json: json_content}, {
          headers: {
            // 'Content-Type': 'application/json' //this enable CORS (OPTIONS+POST) !
            'Content-Type': 'text/plain' 
          }
        })
        .then(response => {
          //parse id
          parsedId = response.data
          console.log(parsedId)

          // //show id
          // this.dialog = true
          // this.dialogText = this.$API_URL+'/index.html?show='+parsedId            
          window.close();

        })
      },      

      save_with_contact_data() {
        //get json
        let json_content = JSON.stringify(this.$refs.model3d2.get_model_json())
        let parsedId

        // console.log(this.modal_email);
        // console.log(this.modal_phone);
        
        

        this.modelDescId = this.$route.query.show

        axios.post(this.$API_URL+'/api/', {method: 'save_model_desc', make_screenshot: '0', model_id: this.modelId, model_desc_id: this.modelDescId, order_key:this.orderKey, user_key:this.userKey, user_email: this.modalEmail, user_phone: this.modalPhone, user_message: 'rozmiar:'+this.modalSize +', typ:'+ this.modalType +', jest nagrobek:'+ this.modalExists, json: json_content}, {
          headers: {
            // 'Content-Type': 'application/json' //this enable CORS (OPTIONS+POST) !
            'Content-Type': 'text/plain' 
          }
        })
        .then(response => {
          //parse id
          parsedId = response.data
          console.log(parsedId)

          // // //show id
          // this.dialog = true
          // this.dialogText = this.$API_URL+'/?show='+parsedId            
          // window.close();
          this.saving3 = false
          this.dialog3 = false

        })
      },   
      
      send_pass() {
        this.modelDescId = this.$route.query.show
        console.log(this.orderKey)
        console.log(this.userKey)
        axios.post(this.$API_URL+'/api/', {method: 'send_sms_pass', pass:'2'+this.configPass+this.configPassOneTime+'5', model_id: this.modelId, model_desc_id: this.modelDescId, order_key:this.orderKey, user_key:this.userKey, user_phone: this.modalPhone}, {
          headers: {
            // 'Content-Type': 'application/json' //this enable CORS (OPTIONS+POST) !
            'Content-Type': 'text/plain' 
          }
        })
      },

      print_obj_txt_coords() {
        
        this.$refs.model3d2.set_z_coords_to_value();

        var data=this.$refs.model3d2.get_model_json();

        console.log(JSON.stringify(data.objPositions));
        console.log(JSON.stringify(data.txtPositions));
      },

      copy_to_clipboard() {
        navigator.clipboard.writeText(this.dialogText)
      },

      validateForm() {
        if(this.$refs.form.validate()) {
          this.saving3=true
          this.save_with_contact_data()
        }
      },
      validateFormSendPass() {
        if(this.$refs.form.validate()) {
          this.send_pass()
        }
      },

      changeRodoMessage() {
        this.configRodoText1 = this.configRodoText2;
        this.changeRodoMessageVisible = false;
      }

    },
    watch: {
      modalPass(newPass) {
        if (newPass === this.configPassOneTime || newPass === this.configPass) {
          this.dialogTimeOut = false;
        }
      }
    },
    created(){
      // this.load_all_models(this.modelId)

      let httpModelId = this.$route.query.show
      this.orderKey = this.$route.query.order_key
      this.userKey = this.$route.query.user_key
      this.showContactData = this.$route.query.show_contact_data
      
      //chowamy wycene, jesli byl ustawiony $_GET['price_visibility'] i był pusty
      if (typeof this.$route.query.price_visibility !== 'undefined') {
        this.price_visibility = this.$route.query.price_visibility
      }

      if(httpModelId) {
        // console.log(httpModelId)
        // this.modelDescId = this.modelId
        this.modelDescId = httpModelId
        this.modelId = httpModelId
      } else {
        window.location.href = "https://gravecreator.pl"
      }

      // this.load_model_desc(this.modelId, this.modelDescId)
      this.load_config_data(this.modelId)
      this.load_model_desc(this.modelId)
      
    },
    mounted(){
      var timesFontFace = new FontFace('Times', 'url(/assets/fonts/TimesNewRoman.ttf)');
      document.fonts.add(timesFontFace);
      var arialFontFace = new FontFace('Arial', 'url(/assets/fonts/Arial.ttf)');
      document.fonts.add(arialFontFace);
      var arialNarrowFontFace = new FontFace('ArialNarrow', 'url(/assets/fonts/ArialNarrow.ttf)');
      document.fonts.add(arialNarrowFontFace);
      var CenturyGothic = new FontFace('CenturyGothic', 'url(/assets/fonts/CenturyGothic.ttf)');
      document.fonts.add(CenturyGothic);    

      this.$refs.model3d2.setTextures()
      this.$refs.model3d2.startApp()

      if(this.userKey) {
        this.dialogStart = true;
      }

      //npn = no pass needed
      if(this.$route.query.npn!='1') {
        setTimeout(() => {
        this.dialogTimeOut = true;
      }, 30000);

      }
    }

  }
</script>

<style lang="scss">

// @import '~pretty-checkbox/src/pretty-checkbox.scss';

.v-expansion-panel-content .v-textarea {
  font-size: 0.8em;
}

.v-expansion-panel-content__wrap {
  padding:0 12px 16px !important;
}

// .p-on {
//   width:0px;
// }
// .p-on img{
//   outline: 3px solid red;
// }
// .p-image-model  {
//   font-size:100px;
//   margin-right: 100px;
// }

// .p-image-color  {
//   font-size:128px;
//   margin-right: 114px;
// }

.v-list-item__subtitle{
justify-content: center;
text-align: left;
display: grid;
}

.radio_color{margin-bottom:15px}
.radio_color .v-input--selection-controls__input{display:none}
.radio_color span{font-size:10px}

.radio_models{margin-bottom:15px}
.radio_models .v-input--selection-controls__input{display:none}
.radio_models span{font-size:10px}

.v-alert__content{font-size:12px}

#watermark {
  position: absolute;
  opacity: 0.4;
  pointer-events: none;
  transform-style: preserve-3d;
  background-size: cover; 
  background-repeat: repeat; 
  background-image:url(https://app2.gravecreator.pl/assets/logos/gravecreator_watermark.png);
  // position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;  
}
#watermark img{
  // width:1920px;
  // height:1080px;
}
.dimmed-image {
  opacity: 0.5; 
  filter: brightness(50%);
}
.overlay-text {
  position: absolute;
  top: 50%; /* Centruje tekst w pionie */
  left: 50%; /* Centruje tekst w poziomie */
  transform: translate(-50%, -50%);
  color: white; /* Kolor tekstu */
  opacity: 0.7; /* Poziom przezroczystości tekstu */
  font-size: 1.5em; /* Rozmiar czcionki */
  pointer-events: none; /* Zapobiega kliknięciu na tekst */
  text-align:center;
}
.logo_text{
  text-decoration:none;
  color:rgba(0, 0, 0, 0.6) !important
}
</style>
