<template>
    <div id="container"></div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
// import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';

// import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import {isEqual} from "lodash";
import { DragControls } from "three/examples/jsm/controls/DragControls.js";
// import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'
import axios from 'axios';
import  FontFaceObserver from 'fontfaceobserver/fontfaceobserver.js';

		import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
		import { SSRPass } from 'three/examples/jsm/postprocessing/SSRPass.js';
		import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
		import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';
		// import { ReflectorForSSRPass } from './jsm/objects/ReflectorForSSRPass.js';

export default {
  name: 'Model3d',
  props: {
    modelData: {
      type: Array,
      required: true
    },
    modelShapesData: {
      type: Array,
      required: true
    },
    texturesData: {
      type: Array,
      required: true      
    },
    modelId: {
      type: String,
      required: true,
    },
    modelTablica: {
      type: String,
      required: true,
    },
    // modelColour1: {
    //   type: String,
    //   required: true,
    // },
    // modelColour2: {
    //   type: String,
    //   required: true,
    // },
    // modelColour3: {
    //   type: String,
    //   required: true,
    // },        
    modelColours: {
      type: Array,
      required: true,
    },    
    modelTxts: {
      type: Array,
      required: true,
    },
    modelTxtsColours: {
      type: Array,
      required: true,
    },
    modelTxtsFonts: {
      type: Array,
      required: true,
    },
    modelTxtsFontsStyle: {
      type: Array,
      required: true,
    },        
    modelTxtsSize: {
      type: Array,
      required: true,      
    },
    modelShapes: {
      type: Array,
      required: true,
    },            
    modelShapesColours: {
      type: Array,
      required: true,
    },                
    modelShapesSize: {
      type: Array,
      required: true,      
    },    
    z_coords:{
      type:String,
      required: true,
    },
  },
  data() {
    return {
      stat_enabled: false,
      ssr_enabled: false,
      stat: null,
      camera: null,
      scene: null,
      renderer: null,
      composer: null,
      ssrPass: null,
      controls: null,
      mesh: null,
      // draggableObjects: [],
      draggableObjectsTexts: [],
      draggableObjectsShapes: [],
      dragControls: null,
      mouse: null,
      plane: null,
      raycaster: null, 
      intersects: null, 
      geometry: null,
      textures: [],
      textureCube: null,
      tableEdit: false, //locks moving texts and shapes 

      modelTablicaData: [
        {id: 0, path: '/assets/m39.gltf'},
        {id: 1, path: '/assets/m37.gltf'},
        {id: 2, path: '/assets/m35.gltf'},
        {id: 3, path: '/assets/m34.gltf'},
      ],
      // modelShapesData: [
      //   {id: 0, path: '/assets/shapes/cross_01.obj', 
      //   },
      //   {id: 1, path: '/assets/shapes/cross_02.obj', 
      //   },
      //   {id: 2, path: '/assets/shapes/cross_03.obj', 
      //   },                
      // ],
      // textureData: [
      //   {id: 0, path: '/assets/black-stones-tile512.jpg'},
      //   {id: 1, path: '/assets/granite_green_512.jpg'},
      //   {id: 2, path: '/assets/granite_red_512.jpg'},
      //   {id: 3, path: '/assets/granite_black_512.jpg'},
      // ],
      textColourData: [
        {id: 0, value: 'black', hex: 0x000000},
        {id: 1, value: 'white', hex: 0xFFFFFF},
        {id: 2, value: 'gold', hex: 0xFFD700}
      ],
      textFontFamily: [
        {id: 0, value: 'Times'},
        {id: 1, value: 'Arial'},
        {id: 2, value: 'ArialNarrow'},
        {id: 3, value: 'CenturyGothic'},
      ],
      textFontStyle: [
        {id: 0, value: ''},
        {id: 1, value: 'italic'}
      ],      
      modelIdOld: null,
      modelTablicaOld: null,
      // modelColour1Old: null,
      // modelColour2Old: null,
      // modelColour3Old: null,
      modelColoursOld: null,
      modelTxtsOld: [],
      modelTxtsColoursOld: null,
      modelTxtsFontsOld: null,
      modelTxtsFontsStyleOld: null,
      modelTxtsSizeOld: null,
      modelShapesOld: null,
      modelShapesColoursOld: null,
      modelShapesSizeOld: null,
    }
  },
  methods: {
    async loadGltfModel() {
        const loader2 = new GLTFLoader();
        // console.log('load');
        // const model_path = './nagrobek_120_250.gltf';
        // console.log('****modelId: '+this.modelId);
        // console.log(this.modelData)
        
        // const model_path = this.modelData[this.modelId].path;
        //const model_path = this.$API_URL+'/api/' + '?method=load_model&model_id='+this.modelData[this.modelId].id
        // const model_path = "data:application/octet-stream;base64,"+
        // console.log('model_path: '+model_path);
        
        
        // console.log(this.modelData[this.modelId].id)
        // const model_path = '/assets/nagrobek_120_250_4.gltf'

        if(!this.modelData[this.modelId]) {
          return false;
        }
        
        const model_path = this.$API_URL+'/api/' + '?method=load_model&model_id='+this.modelData[this.modelId].parent_id

        let jsonGltf

        const req = axios.get(model_path)
        req instanceof Promise
        await req
        
        req
        .then(response => {jsonGltf = window.atob(response.data.substr(2))
        
          loader2.parse(jsonGltf, '', 
            gltf => {
              gltf.scene.name = 'model_0'
              this.scene.add(gltf.scene);

              //list all
              gltf.scene.traverse( node => {
                if (node.isMesh) {
                  console.log(node.name);
                }
              });

              //apply texture
              gltf.scene.traverse( node => {
                if (node.isMesh) {
                  // console.log(counter++);
                  // console.log(node.name);
                  // if(counter>2)

                  //na razie wylaczamy wszsytkie tablice w calych modelach i pokazujemy zaladowane luzem tablice
                  // if(node.name.toLowerCase().startsWith('tablica'))
                  // {
                  //   node.visible = false;
                  // }

                  let meshId = this.modelData[this.modelId].elements.find(x => x.name === node.name).id;
                  let color = this.modelColours[meshId];

                  node.material.map = this.textures[color];
                  node.material.metal = 0.5;
                  node.material.roughness = 0.01;
                  node.material.flatShading = THREE.FlatShading;
                  node.material.envMap = this.textureCube;                
                }
            });
              
              const box = new THREE.Box3().setFromObject( gltf.scene );
              const center = box.getCenter( new THREE.Vector3() );

              gltf.scene.position.x += ( gltf.scene.position.x - center.x );
              gltf.scene.position.y += ( gltf.scene.position.y - center.y );
              gltf.scene.position.z += ( gltf.scene.position.z - center.z );
              
            },
            undefined,
            undefined
          )

        });




    },
    loadGltfModel2() {
				const loader2 = new GLTFLoader();
				const model_path = this.modelTablicaData[this.modelTablica-1].path;
        // const model_path = this.modelData[this.modelId].path;

        loader2.load(
          model_path,
          gltf => {
            gltf.scene.name = 'model_1'
            this.scene.add(gltf.scene);

            // let counter=0;

            //apply texture
            gltf.scene.traverse( node => {
              if (node.isMesh) {

                //na razie zakladam, ze kolor tablicy bedzie to 1. mesh (z indexem 0)
                //to jest prawda, ale tylko dla prostych nagrobkow
                let color = this.modelColours[0];

                node.material.map = this.textures[color];

                node.material.metal = 0.5;
                node.material.roughness = 0.1;
                node.material.flatShading = THREE.FlatShading;
                node.material.envMap = this.textureCube;                
              }
           });
            
            // const box = new THREE.Box3().setFromObject( gltf.scene );
            // const center = box.getCenter( new THREE.Vector3() );

            let localScale = 0.8;
            gltf.scene.scale.x = localScale;
            gltf.scene.scale.y = localScale;
            gltf.scene.scale.z = localScale;
            

            gltf.scene.position.x += ( gltf.scene.position.x - 0 );
            gltf.scene.position.y += ( gltf.scene.position.y - 0.15 );
            gltf.scene.position.z += ( gltf.scene.position.z - 1.18 );
            
          },
        undefined,
        undefined
      )              
    },

    // makeTexture() {
    //   const cnv = document.createElement("canvas");
    //   const ctx = cnv.getContext('2d');
    //   // const svg = document.getElementById('svg');
    //   // const svgData = (new XMLSerializer()).serializeToString(svg);
    //   const base64 = window.btoa(unescape(encodeURIComponent('<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve"><polygon points="36.5,13 36.5,0 23.5,0 23.5,13 8.5,13 8.5,26 23.5,26 23.5,60 36.5,60 36.5,26 51.5,26 51.5,13 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>')));
    //   // const base64 = window.btoa(unescape(encodeURIComponent('<svg width="200" height="200" xmlns="http://www.w3.org/2000/svg" version="1.1"><rect width="200" height="200" fill="lime" stroke-width="4" stroke="pink" /><circle cx="125" cy="125" r="75" fill="orange" /><polyline points="50,150 50,200 200,200 200,100" stroke="red" stroke-width="4" fill="none" /><line x1="50" y1="50" x2="200" y2="200" stroke="blue" stroke-width="4" /></svg>')));
    //   const src = "data:image/svg+xml;base64," + base64;

    //   const img = new Image();
      
    //   img.onload = () => {
    //     ctx.drawImage(img, 0, 0);
    //     texture.needsUpdate = true;
    //     this.renderer.render(this.scene, this.camera);
    //   }
      
    //   img.src = src;
      
    //   const texture = new THREE.Texture(cnv);
      
    //   return texture;
    // },

    // loadShape3() {

    //   let txtId = 0;

    //   // var svg = document.getElementById("svgContainer").querySelector("svg");
    //   // var svgData = (new XMLSerializer()).serializeToString(svg);
    //   var mesh 
    //   var canvas = document.createElement("canvas");
    //   // var svgSize = svg.getBoundingClientRect();
    //   canvas.width = 100;//svgSize.width;
    //   canvas.height = 50;//svgSize.height;
    //   var ctx = canvas.getContext("2d");

    //   // var img = document.createElement("img");
    //   // img.setAttribute("src", "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent('<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve"><polygon points="36.5,13 36.5,0 23.5,0 23.5,13 8.5,13 8.5,26 23.5,26 23.5,60 36.5,60 36.5,26 51.5,26 51.5,13 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>'))) );

    //   // img.onload = () => {
   
    //   // };
    //   var img = document.createElement("img");
    //     ctx.drawImage(img, 0, 0);

    //     var texture = this.makeTexture()//new THREE.Texture(canvas);
    //     texture.needsUpdate = true;

    //     // var geometry = new THREE.SphereGeometry(3, 50, 50, 0, Math.PI * 2, 0, Math.PI * 2);
    //     var geometry = new THREE.PlaneGeometry(1, 0.5);
    //     // var material = new THREE.MeshBasicMaterial({ map: texture });
    //     var material = new THREE.MeshBasicMaterial( { color: new THREE.Color().setStyle(0xff0000), side:THREE.DoubleSide, map:texture, transparent:true, opacity:1.0 } );
    //     material.map.minFilter = THREE.LinearFilter;
    //     mesh = new THREE.Mesh(geometry, material);

    //     mesh.position.set(
    //       this.modelData[this.modelId].objPositions[txtId].x,
    //       this.modelData[this.modelId].objPositions[txtId].y,
    //       this.modelData[this.modelId].objPositions[txtId].z);   

    //     mesh.name = 'shape_'+txtId;
    //     mesh.gc_index = txtId;
        
    //     this.scene.add(mesh);
    //     this.draggableObjectsShapes.push( mesh );   

    // },

    loadShape() {
    for (let txtId of [...Array(this.modelShapes.length).keys()]) {
        if (this.modelShapesData[this.modelShapes[txtId]]) {
            const loader = new SVGLoader();

            loader.load(this.modelShapesData[this.modelShapes[txtId]].svg_path, (data) => {
                const paths = data.paths;
                const shapeScale = (3 + this.modelShapesSize[txtId]) / 100000;
                const geometries = [];

                const fillColor = this.textColourData[this.modelShapesColours[txtId] - 1].hex;
                if (fillColor !== undefined && fillColor !== 'none') {
                    const material = new THREE.MeshBasicMaterial({
                        color: new THREE.Color(fillColor),
                        side: THREE.DoubleSide,
                        wireframe: false
                    });

                    paths.forEach(path => {
                        path.toShapes(true).forEach(shape => {
                            const geometry = new THREE.ShapeBufferGeometry(shape);
                            geometry.scale(shapeScale, shapeScale, shapeScale);
                            geometries.push(geometry);
                        });
                    });

                    if (geometries.length > 0) {
                        const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(geometries);
                        const mesh = new THREE.Mesh(mergedGeometry, material);

                        if (!this.modelData[this.modelId].objPositions[txtId]) {
                            let lastObjPosition = {
                                x: this.modelData[this.modelId].objPositions[txtId - 1].x + 0.1,
                                y: this.modelData[this.modelId].objPositions[txtId - 1].y + 0.1,
                                z: this.modelData[this.modelId].objPositions[txtId - 1].z
                            };

                            this.modelData[this.modelId].objPositions.push(lastObjPosition);
                        }

                        mesh.position.set(
                            this.modelData[this.modelId].objPositions[txtId].x,
                            this.modelData[this.modelId].objPositions[txtId].y,
                            this.modelData[this.modelId].objPositions[txtId].z
                        );

                        mesh.name = 'shape_' + txtId;
                        mesh.gc_index = txtId;

                        this.scene.add(mesh);
                        this.draggableObjectsShapes.push(mesh);
                        // this.initDragControls(mesh);
                    }
                }
            });
        }
    }
},


    
    // loadShape() {

    //   for(let txtId of [...Array(this.modelShapes.length).keys()]) {
      
    //     if(this.modelShapesData[this.modelShapes[txtId]]) {
       
    //       const loader = new SVGLoader();

    //       loader.load(this.modelShapesData[this.modelShapes[txtId]].svg_path, (data) => {
              
    //         var paths = data.paths;

    //         for (var i = 0; i < paths.length; i++) {
    //             var path = paths[i];
    //             var fillColor = this.textColourData[this.modelShapesColours[txtId]-1].hex
    //             //if (guiData.drawFillShapes && fillColor !== undefined && fillColor !== 'none') {
    //             if (true && fillColor !== undefined && fillColor !== 'none') {
    //                 var material = new THREE.MeshBasicMaterial({
    //                     color: new THREE.Color(fillColor),
    //                     // opacity: path.userData.style.fillOpacity,
    //                     // transparent: path.userData.style.fillOpacity < 1,
    //                     side: THREE.DoubleSide,
    //                     // depthWrite: false,
    //                     wireframe: false
    //                 });

    //                 var shapes = path.toShapes(true);
    //                 for (var j = 0; j < shapes.length; j++) {
    //                     var shape = shapes[j];
    //                     var geometry = new THREE.ShapeBufferGeometry(shape);
    //                     var mesh = new THREE.Mesh(geometry, material);
    //                     // group.add(mesh);

    //                 let shapeScale = (3+this.modelShapesSize[txtId])/100000;

    //                 mesh.scale.x = shapeScale
    //                 mesh.scale.y = shapeScale
    //                 mesh.scale.z = shapeScale             
                    
    //                 // mesh.geometry.rotateZ( Math.PI );

    //                 //set position of NEW shape
    //                 if(!this.modelData[this.modelId].objPositions[txtId]) {

    //                   let lastObjPosition = {
    //                     x: this.modelData[this.modelId].objPositions[txtId-1].x+0.1,
    //                     y: this.modelData[this.modelId].objPositions[txtId-1].y+0.1,
    //                     z: this.modelData[this.modelId].objPositions[txtId-1].z
    //                   };

    //                   this.modelData[this.modelId].objPositions.push(lastObjPosition);
    //                 }

    //                 mesh.position.set(
    //                   this.modelData[this.modelId].objPositions[txtId].x,
    //                   this.modelData[this.modelId].objPositions[txtId].y,
    //                   this.modelData[this.modelId].objPositions[txtId].z);

    //                 // mesh.geometry.attributes.position.needsUpdate = true;

    //                 // console.log('set shape mesh pos: ');
    //                 // console.log(mesh.position);

    //                 mesh.name = 'shape_'+txtId;
    //                 mesh.gc_index = txtId;

    //                 this.scene.add(mesh);
    //                 this.draggableObjectsShapes.push( mesh );

    //                 }

    //             }

    //             // var strokeColor = path.userData.style.stroke;
    //             // //if (guiData.drawStrokes && strokeColor !== undefined && strokeColor !== 'none') {
    //             // if (true && strokeColor !== undefined && strokeColor !== 'none') {
    //             //     var material = new THREE.MeshBasicMaterial({
    //             //         color: new THREE.Color().setStyle(strokeColor),
    //             //         opacity: path.userData.style.strokeOpacity,
    //             //         transparent: path.userData.style.strokeOpacity < 1,
    //             //         side: THREE.DoubleSide,
    //             //         depthWrite: false,
    //             //         wireframe: false
    //             //     });
    //             //     for (var j = 0, jl = path.subPaths.length; j < jl; j++) {
    //             //         var subPath = path.subPaths[j];
    //             //         debugger
    //             //         var geometry = THREE.SVGLoader.pointsToStroke(subPath.getPoints(), path.userData.style);
    //             //         if (geometry) {
    //             //             var mesh = new THREE.Mesh(geometry, material);
    //             //             group.add(mesh);
    //             //         }
    //             //     }
    //             // }
    //         }
    //       })
    //     }

    //     // let geom = new THREE.PlaneBufferGeometry(300, 250, 1, 1);
    //     // let mat = new THREE.MeshBasicMaterial({ color: 0xffffff });
    //     // mat.transparent = true;


    //     // mat.map = this.makeTexture();
    //     // mat.map.minFilter = THREE.LinearFilter;

    //     // const mesh2 = new THREE.Mesh(geom, mat);
    //     // console.log(mesh2);

    //     // var loadSvg = require('load-svg')
    //     // var parsePath = require('extract-svg-path').parse
    //     // var svgMesh3d = require('svg-mesh-3d')

    //     // var mesh

    //     // loadSvg('/assets/shapes/cross.cvg', (err, svg) => {
    //     //   if (err) throw err

    //     //   var svgPath = parsePath(svg)
    //     //   mesh = svgMesh3d(svgPath, {
    //     //     delaunay: false,
    //     //     scale: 4
    //     //   })
    //     // })      


    //     // var mesh = this.dcText(
    //     //     'XXXXXXXX',
    //     //     0xffffff, null, 
    //     //     120
    //     //     );      
    //   }



    // },
    // loadShape_old() {
    //   for(let txtId of [...Array(this.modelShapes.length).keys()]) {

    //     const loader = new OBJLoader();

    //     loader.load( this.modelShapesData[this.modelShapes[txtId]].obj_path,

    //       mesh => {
    //         var ship_material = new THREE.MeshBasicMaterial();
            
    //         mesh.traverse(  child => {
    //           if ( child.isMesh) {
    //               child.material = ship_material;
    //               child.material.color.setHex(this.textColourData[this.modelShapesColours[txtId]-1].hex);
    //           }
    //         });

    //         let shapeScale = (3+this.modelShapesSize[txtId])/20;

    //         mesh.scale.x = shapeScale;
    //         mesh.scale.y = shapeScale;
    //         mesh.scale.z = shapeScale;

    //         //set position of NEW text
    //         if(!this.modelData[this.modelId].objPositions[txtId]) {

    //           let lastTxtPosition = {
    //             x: this.modelData[this.modelId].objPositions[txtId-1].x+0.1,
    //             y: this.modelData[this.modelId].objPositions[txtId-1].y-0.1,
    //             z: this.modelData[this.modelId].objPositions[txtId-1].z
    //           };

    //           this.modelData[this.modelId].objPositions.push(lastTxtPosition);
    //         }
    //         // console.log(txtId)
    //         mesh.position.set(
    //           this.modelData[this.modelId].objPositions[txtId].x,
    //           this.modelData[this.modelId].objPositions[txtId].y,
    //           this.modelData[this.modelId].objPositions[txtId].z);

    //         mesh.name = 'shape_'+txtId;
            
    //         mesh.gc_index = txtId;

    //         this.scene.add( mesh );
    //         this.draggableObjectsShapes.push( mesh );

    //         // const box = new THREE.BoxHelper( object, 0xffff00 );
    //         // box.name = 'shape_box_0'
    //         // this.scene.add( box );
    //         // this.draggableObjects.push( box );          
    //       }
    //     );  
    //   }
    // },

    setTextOnModel(){
      for(let txtId of [...Array(this.modelTxts.length).keys()]) {

        var fontFamily = this.textFontFamily[this.modelTxtsFonts[txtId]-1].value;
        var fontStyle = this.textFontStyle[this.modelTxtsFontsStyle[txtId]-1].value;

        var mesh = this.dcText(
            this.modelTxts[txtId],
            this.textColourData[this.modelTxtsColours[txtId]-1].hex, 
            null, //0xff0000, 
            20+this.modelTxtsSize[txtId],
            fontFamily,
            fontStyle
            );
        mesh.name = 'text_'+txtId

        //set position of NEW text
        if(!this.modelData[this.modelId].txtPositions[txtId]) {

          let lastTxtPosition = {
            x: this.modelData[this.modelId].txtPositions[txtId-1].x,
            y: this.modelData[this.modelId].txtPositions[txtId-1].y-0.050,
            z: this.modelData[this.modelId].txtPositions[txtId-1].z
          };

          this.modelData[this.modelId].txtPositions.push(lastTxtPosition);
        }
        
        mesh.gc_index = txtId;

        // mesh.position.set(this.modelData[this.modelId].txtPositions[txtId]);
        mesh.position.set(
          this.modelData[this.modelId].txtPositions[txtId].x,
          this.modelData[this.modelId].txtPositions[txtId].y,
          this.modelData[this.modelId].txtPositions[txtId].z); 
        this.scene.add(mesh);
        // console.log('loadText:'+this.modelTxts[txtId]);
        // console.log('add '+mesh.name+' | '+this.modelTxts[txtId]);

        // console.log('---settext:')
        // console.log(this.modelId)
        // console.log(this.modelData[this.modelId].path)
        // console.log(this.modelData[this.modelId].txtPositions[0].z)
        // console.log('modelData:')
        // console.log(this.modelData[0].txtPositions[0].z)
        // console.log(this.modelData[1].txtPositions[0].z)
        // console.log(this.modelData[8].objPositions[0].z)
        // console.log(this.modelData[9].objPositions[0].z)
                
        this.draggableObjectsTexts.push( mesh );
        // console.log(this.draggableObjects);
      }

    },

    //https://discourse.threejs.org/t/an-example-of-text-to-canvas-to-texture-to-material-to-mesh-not-too-difficult/13757
    dcText(txt, fgcolor, bgcolor, fontSize, fontFamily, fontStyle) { // the routine
      // txt is the text.
      // hWorldTxt is world height of text in the plane.
      // hWorldAll is world height of whole rectangle containing the text.
      // hPxTxt is px height of text in the texture canvas; larger gives sharper text.
      // The plane and texture canvas are created wide enough to hold the text.
      // And wider if hWorldAll/hWorldTxt > 1 which indicates padding is desired.

      let hWorldTxt = 0.1
      // let hWorldAll = 0.1;
      let hPxTxt = 120;

      var kPxToWorld = hWorldTxt/hPxTxt;                // Px to World multplication factor
      // hWorldTxt, hWorldAll, and hPxTxt are given; get hPxAll
      // var hPxAll = Math.ceil(hWorldAll/kPxToWorld);     // hPxAll: height of the whole texture canvas
      
      // create the canvas for the texture
      var txtcanvas = document.createElement("canvas"); // create the canvas for the texture
      var ctx = txtcanvas.getContext("2d");
      ctx.font = fontStyle + " 220px "+fontFamily;
      
      // now get the widths
      var metrics = ctx.measureText(txt);

      var wPxTxt = metrics.width*fontSize/220*1.1;         // wPxTxt: width of the text in the texture canvas
      
      // let actualHeight = (metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent)*fontSize/200;
      let actualHeight2 = ctx.measureText('W').width*1.5*fontSize/220;

      let hWorldAll = actualHeight2*kPxToWorld;

      var hPxAll = Math.ceil(hWorldAll/kPxToWorld);     // hPxAll: height of the whole texture canvas
    
      var wWorldTxt = wPxTxt*kPxToWorld;               // wWorldTxt: world width of text in the plane
      var wWorldAll = wWorldTxt; // wWorldAll: world width of the whole plane

      var wPxAll = Math.ceil(wWorldAll/kPxToWorld);    // wPxAll: width of the whole texture canvas
      // next, resize the texture canvas and fill the text
      txtcanvas.width =  wPxAll;
      txtcanvas.height = hPxAll;
      if (bgcolor != undefined) { // fill background if desired (transparent if none)
        ctx.fillStyle = "#" + bgcolor.toString(16).padStart(6, '0');
        ctx.fillRect( 0,0, wPxAll,hPxAll);
      } 
      ctx.textAlign = "center";
      ctx.textBaseline = "middle"; 
      ctx.fillStyle = "#" + fgcolor.toString(16).padStart(6, '0'); // fgcolor
      ctx.font = fontStyle + " " + fontSize + "px "+fontFamily;   // needed after resize
      ctx.fillText(txt, wPxAll/2, hPxAll/2); // the deed is done
      // next, make the texture
      var texture = new THREE.Texture(txtcanvas); // now make texture
      texture.minFilter = THREE.LinearMipmapLinearFilter;     // eliminate console message
      texture.needsUpdate = true;                 // duh
      // and make the world plane with the texture
      this.geometry = new THREE.PlaneGeometry(wWorldAll, hWorldAll);
      var material = new THREE.MeshBasicMaterial( 
        { side:THREE.DoubleSide, map:texture, transparent:true, opacity:1.0 } );
      // and finally, the mesh
      var mesh = new THREE.Mesh(this.geometry, material);
      mesh.wWorldTxt = wWorldTxt; // return the width of the text in the plane
      mesh.wWorldAll = wWorldAll; //    and the width of the whole plane
      mesh.wPxTxt = wPxTxt;       //    and the width of the text in the texture canvas
                                  // (the heights of the above items are known)
      mesh.wPxAll = wPxAll;       //    and the width of the whole texture canvas
      mesh.hPxAll = hPxAll;       //    and the height of the whole texture canvas
      mesh.ctx = ctx;             //    and the 2d texture context, for any glitter
      // console.log(wPxTxt, hPxTxt, wPxAll, hPxAll);
      // console.log(wWorldTxt, hWorldTxt, wWorldAll, hWorldAll);
      return mesh;
    },

    setTextures() {
      // textures
      var textureLoader = new THREE.TextureLoader();

      for( let i = 0; i < this.texturesData.length ; i++) { 

        this.textures[i] = textureLoader.load(this.texturesData[i].path);
        this.textures[i].encoding = THREE.sRGBEncoding;
        this.textures[i].flipY = false;
        
        // this.textures[i].repeat.set(1.5, 1.5);
        // this.textures[i].offset.set(0.5, 0.5);
        this.textures[i].wrapS = THREE.RepeatWrapping;
        this.textures[i].wrapT = THREE.RepeatWrapping;
        this.textures[i].anisotropy = 4;
        this.textures[i].needsUpdate = true;      
      }

    },
    onWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize( window.innerWidth, window.innerHeight );
      // this.updateWatermarkPosition();
    },
    // updateWatermarkPosition() {
      // var watermarkElement = document.getElementById('watermark');
      // var canvasRect = this.renderer.domElement.getBoundingClientRect();
      // var watermarkRect = watermarkElement.getBoundingClientRect();

      // // var watermarkX = (canvasRect.width + 300 - watermarkRect.width) / 2; // Umieść na środku
      // // var watermarkY = (canvasRect.height - watermarkRect.height) / 2; // Umieść na środku

      // // watermarkElement.style.left = watermarkX + 'px';
      // // watermarkElement.style.top = watermarkY + 'px'; 

      // // console.log('waterCanvasRectW:'+canvasRect.width);     
      // // console.log('waterCanvasRectH:'+canvasRect.height);

      // // console.log('waterCanvasRectW:'+watermarkRect.width);     
      // // console.log('waterCanvasRectH:'+watermarkRect.height);

      // // console.log('watermarkx:'+watermarkX);     
      // // console.log('watermarky:'+watermarkY);
    // },
    init: function() {

      // var timesFontFace = new FontFace('Times', 'url(/assets/fonts/TimesNewRoman.ttf)');
      // document.fonts.add(timesFontFace);
      // var arialFontFace = new FontFace('Arial', 'url(/assets/fonts/Arial.ttf)');
      // document.fonts.add(arialFontFace);
      // var arialNarrowFontFace = new FontFace('ArialNarrow', 'url(/assets/fonts/ArialNarrow.ttf)');
      // document.fonts.add(arialNarrowFontFace);
      // var CenturyGothic = new FontFace('CenturyGothic', 'url(/assets/fonts/CenturyGothic.ttf)');
      // document.fonts.add(CenturyGothic);            

      if(this.stat_enabled) {this.stats = new Stats();this.stats.domElement.style.left = '400px'}
      this.container = document.getElementById( 'container' );

      this.camera = new THREE.PerspectiveCamera( 35, window.innerWidth / window.innerHeight, 0.1, 500 );
      this.camera.position.set( -3, 1, 4 );
      // this.camera.position.set( 0,0,2);
      this.camera.lookAt( 0, 0, 0 );

      //background color
      this.scene = new THREE.Scene();
      
      
      if(this.$route.query.screenshot_headless=='1') {
        this.scene.background = new THREE.Color( 0xffffff );
        // document.getElementById('watermark').style.display = 'none';
        
      } else {
        this.scene.background = new THREE.Color( 0xe0e0e0 );
        // document.getElementById('watermark').style.display = 'block';
      }
      

      // show axis
      // var axesHelper = new THREE.AxesHelper( 20 );
      // this.scene.add( axesHelper );
      
      // var gridHelper = new THREE.GridHelper( 10, 10 );
      // this.scene.add( gridHelper );	


      const r = '/assets/textures/Standard-Cube-Map_brown/';
      const urls = [ r + 'px.jpg', r + 'nx.jpg',
        r + 'py.jpg', r + 'ny.jpg',
        r + 'pz.jpg', r + 'nz.jpg' ];

      // const envTexture = '/assets/textures/black_white_512hv.jpg';
      // const urls = [ envTexture, envTexture, envTexture, envTexture, envTexture, envTexture ];

      this.textureCube = new THREE.CubeTextureLoader().load( urls );

      // scene.background = textureCube;




      // clock = new THREE.Clock();

      // // loading manager
      // const loadingManager = new THREE.LoadingManager( function () {
      // 	scene.add( elf );
      // } );




      // const reflectionCube = new THREE.CubeTextureLoader()
      // .setPath( 'textures/cube/SwedishRoyalCastle/' )
      // .load( [ 'px.jpg', 'nx.jpg', 'py.jpg', 'ny.jpg', 'pz.jpg', 'nz.jpg' ] );
      // const gltfLoader = new GLTFLoader();

      // gltfLoader.load( 'models/gltf/BoomBox.glb', function ( gltf ) {

      // 	const boomBox = gltf.scene;
      // 	boomBox.position.set( 0, 0.2, 0 );
      // 	boomBox.scale.set( 20, 20, 20 );

      // 	boomBox.traverse( function ( object ) {

      // 		if ( object.isMesh ) {

      // 			object.material.envMap = reflectionCube;
      // 			object.geometry.rotateY( - Math.PI );
      // 			object.castShadow = true;

      // 		}

      // 	} );

      // 	// boomBox.add( positionalAudio );
      // 	scene.add( boomBox );
      // 	animate();

      // } );			
      
      //invoke only in animate()
      // this.setTextures();
      // this.loadGltfModel();
      // this.setTextOnModel();
      




      //

      // const hemiLight = new THREE.HemisphereLight( 0x443333, 0x111122 );
			// this.scene.add( hemiLight );

      // const ambientLight = new THREE.AmbientLight( 0xcccccc, 0.4 );
      // this.scene.add( ambientLight );

      // const ambientLight2 = new THREE.AmbientLight( 0xffffff, 0.8 );
      // this.scene.add( ambientLight2 );

      // const directionalLight = new THREE.DirectionalLight( 0x880000, 0.7 );
      // directionalLight.position.set( 2, 2, -2 ).normalize();
      // this.scene.add( directionalLight );

      // const directionalLight2 = new THREE.DirectionalLight(0xff0000, 0.8);
      // directionalLight2.position.set(2, 2, -2);
      // this.scene.add(directionalLight2); 				

      // const directionalLight3 = new THREE.DirectionalLight(0xffffff, 0.3);
      // directionalLight3.position.set(-20, -50, 30);
      // this.scene.add(directionalLight3); 		

      this.renderer = new THREE.WebGLRenderer( { antialias: true, autoSize: true } );
      this.renderer.setPixelRatio( window.devicePixelRatio );
      this.renderer.setSize( window.innerWidth, window.innerHeight );
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.container.appendChild( this.renderer.domElement );
      
      if(this.stat_enabled){document.body.appendChild( this.stats.dom );}

      // if(this.$route.query.screenshot_headless=='1') {
      //   var graveCreatorInfo = document.createElement('div');
      //   graveCreatorInfo.style.cssText = 'position:fixed;top:0;width:100%;text-align:center;background:#fff;color:#000;padding:2px;';
      //   graveCreatorInfo.innerHTML = 'GraveCreator.pl'; 
      //   document.body.appendChild(graveCreatorInfo);    
      // }  

      this.controls = new OrbitControls( this.camera, this.renderer.domElement );
      this.controls.target.set( 0, 0, 0 );
      this.controls.minAzimuthAngle = - Math.PI/2;
      this.controls.maxAzimuthAngle = Math.PI/2;
      this.controls.minPolarAngle = - Math.PI/2;
      this.controls.maxPolarAngle = Math.PI/2;
      this.controls.update();     

      // controls.addEventListener( 'change', this.render );   
      
          //       this.setTextOnModel();
          // this.loadShape();
      // console.log(this.draggableObjects);


      //init only to let invoke setDraggableObjects()
      this.dragControls = new DragControls( [], this.camera, this.renderer.domElement );

      this.setDraggableObjects();
        
      window.addEventListener( 'resize', this.onWindowResize );

      window.addEventListener( 'mousemove', this.onMouseMove, false );

      this.mouse = new THREE.Vector2();
      this.plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
      this.raycaster = new THREE.Raycaster();
      this.intersects = new THREE.Vector3();

      if(this.ssr_enabled) {
        this.composer = new EffectComposer( this.renderer );
        this.ssrPass = new SSRPass( {
          renderer: this.renderer,
          scene: this.scene,
          camera: this.camera,
          // width: innerWidth,
          // height: innerHeight,
          // groundReflector: params.groundReflector ? groundReflector : null,
          // selects: params.groundReflector ? selects : null
        } );

        // this.ssrPass.infiniteThick = true;

        this.composer.addPass( this.ssrPass );
        this.composer.addPass( new ShaderPass( GammaCorrectionShader ) );      
      }
      // this.updateWatermarkPosition();
    },
    animate: function() {

      //debug only - move to init, DON'T add every frame!!!!
      // var axesHelper = new THREE.AxesHelper( 20 );
      // this.scene.add( axesHelper );
      
      //debug only - move to init, DON'T add every frame!!!!
      // var gridHelper = new THREE.GridHelper( 10, 10 );
      // this.scene.add( gridHelper );	
     

      requestAnimationFrame(this.animate);
      // this.mesh.rotation.x += 0.01;
      // this.mesh.rotation.y += 0.02;

      //if model changed
      if(this.modelIdOld != this.modelId
        ||this.modelTablicaOld != this.modelTablica
        // || this.modelColour1Old != this.modelColour1
        // || this.modelColour2Old != this.modelColour2
        // || this.modelColour3Old != this.modelColour3
        || !isEqual(this.modelColoursOld ,this.modelColours)
      ) {
        this.modelIdOld = this.modelId;
        this.modelTablicaOld = this.modelTablica;
        // this.modelColour1Old = this.modelColour1;
        // this.modelColour2Old = this.modelColour2;
        // this.modelColour3Old = this.modelColour3;
        this.modelColoursOld = this.modelColours.slice();

        // this.modelColours = []
        // //populate elements colours
        // for(let elId of [...Array(this.modelData[this.modelId].elements.length).keys()]) {
        //   this.modelColours.push(this.modelData[this.modelId].elements[elId].colour)
        // }        
        
// console.log(this.modelTablica);
        //!!! dont change for to forEach - it is not async compatible !!!
        for( let i = this.scene.children.length - 1; i >= 0; i--) { 
          let obj = this.scene.children[i];
// console.log('name: '+obj.name)
          if(!obj.name.startsWith('text_') && !obj.name.startsWith('shape_')) {
            this.scene.remove(obj);
          }
        }

        // this.setTextures();
        this.loadGltfModel();
        // if(this.modelTxtsOld == 'asdf239486') {
          // this.loadGltfModel2();
        // }
        
        //invoke reload txts and shapes
        this.modelTxtsOld = null;

      } else 
      //text changed
      if(  !isEqual(this.modelTxtsOld, this.modelTxts)
        || !isEqual(this.modelTxtsSizeOld ,this.modelTxtsSize)
        || !isEqual(this.modelTxtsColoursOld ,this.modelTxtsColours)
        || !isEqual(this.modelTxtsFontsOld ,this.modelTxtsFonts)
        || !isEqual(this.modelTxtsFontsStyleOld ,this.modelTxtsFontsStyle)
        || !isEqual(this.modelShapesOld ,this.modelShapes)
        || !isEqual(this.modelShapesColoursOld ,this.modelShapesColours)
        || !isEqual(this.modelShapesSizeOld ,this.modelShapesSize)
        ) {
        
        this.modelTxtsOld = this.modelTxts.slice();
        this.modelTxtsSizeOld = this.modelTxtsSize.slice();
        this.modelTxtsColoursOld = this.modelTxtsColours.slice();
        this.modelTxtsFontsOld = this.modelTxtsFonts.slice();
        this.modelTxtsFontsStyleOld = this.modelTxtsFontsStyle.slice();
        this.modelShapesOld = this.modelShapes.slice();
        this.modelShapesColoursOld = this.modelShapesColours.slice();
        this.modelShapesSizeOld = this.modelShapesSize.slice();
// console.log(this.scene.children)
        // console.log('Length:'+this.scene.children.length)
        //!!! dont change for to forEach - it is not async compatible !!!

        // let modelTxtsCount = 0;
      //  console.log(this.scene)
        for( let i = this.scene.children.length - 1; i >= 0; i--) {
          
          let obj = this.scene.children[i];
// console.log(obj.name)
          if(obj.name.startsWith('text_')) {
            // modelTxtsCount = modelTxtsCount+1;
            // console.log('remove T: '+obj.name)
            // console.log('XYZt:'+obj.position.x+' '+obj.position.y+' '+obj.position.z)  

            // console.log('xxxxxxx')
            // console.log(this.modelId)
            // // console.log(this.modelData[this.modelId].txtPositions)
            // console.log(this.modelData[this.modelId].txtPositions[0].z)
            
            //save positions only in tableEdit mode
            if(this.tableEdit) {
              this.modelData[this.modelId].txtPositions[obj.gc_index].x = obj.position.x;
              this.modelData[this.modelId].txtPositions[obj.gc_index].y = obj.position.y;
              this.modelData[this.modelId].txtPositions[obj.gc_index].z = obj.position.z;
            }

            this.scene.remove(obj);
            // console.log('Y'+this.scene.children.length)  
          }

          if(obj.name.startsWith('shape_')) {
            // console.log('remove S: '+obj.name)
            // console.log('XYZs:'+' '+obj.position.x+' '+obj.position.y+' '+obj.position.z)
            // console.log(obj)

            //save positions only in tableEdit mode
            if(this.tableEdit) {
              this.modelData[this.modelId].objPositions[obj.gc_index].x = obj.position.x;
              this.modelData[this.modelId].objPositions[obj.gc_index].y = obj.position.y;
              this.modelData[this.modelId].objPositions[obj.gc_index].z = obj.position.z;
            }

            this.scene.remove(obj);
            // console.log('Y'+this.scene.children.length)  
          }


        }

        this.draggableObjectsAll = [];
        this.draggableObjectsTexts = [];
        this.draggableObjectsShapes = [];
        
        var fontA = new FontFaceObserver('Times');
        var fontB = new FontFaceObserver('Arial');
        var fontC = new FontFaceObserver('ArialNarrow');
        var fontD = new FontFaceObserver('CenturyGothic');

        Promise.all([fontA.load(), fontB.load(), fontC.load(), fontD.load()]).then(() => {
          this.setTextOnModel();
        });

        // var font = new FontFaceObserver(this.textFontFamily[this.modelTxtsFonts[0]-1].value);

        // font.load(null, 5000).then(() => {
        //   // console.log('Times font is available');
        //   this.setTextOnModel();
        // }, function () {
        //   // console.log('Times font is not available after waiting 5 seconds');
        // });

        
        this.loadShape();

        //ERROR, MUST WAIT FOR SHAPE LOAD!
        //Promise doesn't work!
        setTimeout(() => {
          this.setDraggableObjects()
          // this.$emit("wycena_api")
        }
        , 2000);

                 
      }

      // TWEEN.update();

      if(this.ssr_enabled){
        this.composer.render();
      } else {
        this.renderer.render(this.scene, this.camera);        
      }

      if(this.stat_enabled){this.stats.update();}
        
    }, 

    setDraggableObjects: function () {

      //if(this.tableEdit) { this.dragControls.activate();} else {this.dragControls.deactivate();} 

      this.dragControls.deactivate()
      this.draggableObjectsAll = [];

      // console.log(this.draggableObjectsAll);

      if(this.tableEdit){
        // console.log('ADD1:');
        this.draggableObjectsAll = (this.draggableObjectsTexts.concat(this.draggableObjectsShapes)).slice()
        this.controls.enabled = false
      } else {
        // console.log('ADD2:');
        this.draggableObjectsAll = []
        this.controls.enabled = true
      }

      // console.log(this.draggableObjectsTexts);
      // console.log(this.draggableObjectsShapes);
      // console.log(this.draggableObjectsAll);

      // this.draggableObjects = this.draggableObjects.concat(this.draggableObjectsTexts, this.draggableObjectsShapes)

      this.dragControls.dispose();
      this.dragControls = new DragControls( this.draggableObjectsAll, this.camera, this.renderer.domElement );
      this.dragControls.addEventListener( 'dragstart', (event) => { event.object.material.color.set( 0xffff00 );} );
      // this.dragControls.addEventListener( 'drag', (e) => { console.log(e.object) } );
      this.dragControls.addEventListener( 'dragend', (event) => { event.object.material.color.set( 0xffffff );} );

    },

    // panCam: function (xTarget,yTarget,zTarget,tweenDuration){

    //       var camNewPosition= { x : xTarget, y : yTarget, z : zTarget};
    //       // var targetNewPos = {x : xTarget, y : yTarget, z : -1.65};

    //       new TWEEN.Tween(this.camera.position).to(camNewPosition, tweenDuration).easing(TWEEN.Easing.Quadratic.Out)
    //       //  .onComplete(function() {
    //       //      this.camera.position.copy(camNewPosition);
           
    //       //   })
    //       .start();
          
    //       // var endRotation = new THREE.Euler().copy( this.camera.rotation );

    //       // new TWEEN.Tween( this.camera ).to( { rotation: endRotation }, tweenDuration ).start();
    //       // new TWEEN.Tween(this.controls.target).to(targetNewPos, tweenDuration).easing(TWEEN.Easing.Quadratic.Out)
    //       //   .onComplete(function() {
    //       //       this.controls.target.copy(targetNewPos);
    //       //       })
    //       // .start();
    //       },

    setCameraOnTable: function () {
      this.camera.position.set( 0, 0, 1.6);
      this.camera.lookAt( 0, 0, 0 );
      this.tableEdit = true;
      this.setDraggableObjects()

      // var xTarget=0;
      // var yTarget=-0.7;
      // var zTarget=-1.65;
      // var tweenDuration=2000;

      // console.log('start pan');
      // this.camera.lookAt( 0, 0, 0);
      // this.panCam(0,0,2,200);

      // console.log('end pan');

    },

    setCameraOnObject: function () {
      this.camera.position.set( -3, 1, 4 );
      this.camera.lookAt( 0, 0, 0 );
      this.tableEdit = false;
      this.setDraggableObjects()

      // console.log('start pan2');
      // console.log('end pan2');
    },    

    // onInskrypcjeExpansionPanelClick(event) {
    //     if(event.target.classList.contains('v-expansion-panel-header--active')) {
    //       console.log("Panel is closing/now closed.")
    //     } else {
    //       console.log("Panel is opening/now open.")
    //     }
    //   },
      
    make_screenshot: function() {
      var w = window.open('', '');
      w.document.title = "Screenshot";
      //w.document.body.style.backgroundColor = "red";
      var img = new Image();
      // Without 'preserveDrawingBuffer' set to true, we must render now
      this.renderer.render(this.scene, this.camera);
      img.src = this.renderer.domElement.toDataURL();
      w.document.body.appendChild(img);
    },

    save_screenshot: function() {
      // var w = window.open('', '');
      // w.document.title = "Screenshot";
      //w.document.body.style.backgroundColor = "red";

      var modelId
      var httpModelId = this.$route.query.show
      

      if(httpModelId) {
        modelId = httpModelId
      } else {
        modelId = this.modelId
      }

      var img = new Image();
      // Without 'preserveDrawingBuffer' set to true, we must render now
      this.scene.background = new THREE.Color( 0xffffff );
      this.renderer.render(this.scene, this.camera);
      img.src = this.renderer.domElement.toDataURL();
      this.scene.background = new THREE.Color( 0xe0e0e0 );


      // let data = new FormData();
      // data.append('screenshot', img.src);
      // data.append('method', 'save_screenshot');      
      axios.post(this.$API_URL+'/api/?method=save_screenshot&model_id='+modelId, img.src)
      // .then(response => {
      //   console.log(response)
      // })

    },    

    show_model_json: function() {

      var w = window.open('', '');
      w.document.title = "Model json";
      var par = document.createElement("p");
      var text = document.createTextNode(JSON.stringify(this.get_model_json()));
      par.appendChild(text);
      w.document.body.appendChild(par);

    },    

    get_model_json: function(index) {
      

      //copy
      // var exportModelData = this.modelData
      var exportModelData = JSON.parse(JSON.stringify(this.modelData[this.modelId]))

      // console.log(exportModelData)

      for( let i = this.scene.children.length - 1; i >= 0; i--) { 
        let obj = this.scene.children[i];
        
        if(obj.name.startsWith('text_')) {
          exportModelData.txtPositions[obj.gc_index].x = obj.position.x
          exportModelData.txtPositions[obj.gc_index].y = obj.position.y
          exportModelData.txtPositions[obj.gc_index].z = obj.position.z
        }
        
        if(obj.name.startsWith('shape_')) {
          exportModelData.objPositions[obj.gc_index].x = obj.position.x
          exportModelData.objPositions[obj.gc_index].y = obj.position.y
          exportModelData.objPositions[obj.gc_index].z = obj.position.z
        }
      }

      for(let elId of [...Array(this.modelColours.length).keys()]) {
        exportModelData.elements[elId].colour = this.modelColours[elId]
      }

      for(let elId of [...Array(this.modelTxts.length).keys()]) {
        exportModelData.txtPositions[elId].content = this.modelTxts[elId]
        exportModelData.txtPositions[elId].colour = this.modelTxtsColours[elId]
        exportModelData.txtPositions[elId].font = this.modelTxtsFonts[elId]
        exportModelData.txtPositions[elId].font_style = this.modelTxtsFontsStyle[elId]
        exportModelData.txtPositions[elId].size = this.modelTxtsSize[elId]
      }

      for(let elId of [...Array(this.modelShapes.length).keys()]) {
        exportModelData.objPositions[elId].object_id = this.modelShapes[elId]
        exportModelData.objPositions[elId].colour = this.modelShapesColours[elId]
        exportModelData.objPositions[elId].size = this.modelShapesSize[elId]
      }      

      exportModelData.id = null

      console.log(exportModelData.objPositions)

      //remove_shape
      for(let elId of [...Array(exportModelData.objPositions.length).keys()]) {
        if(index==elId) {
          //delete exportModelData.objPositions[elId];
          exportModelData.objPositions.splice(index, 1)
          console.log("delete "+index)
        }  
      }

      console.log(exportModelData.objPositions)
      
      // var w = window.open('', '');
      // w.document.title = "Model json";
      // var par = document.createElement("p");
      // var text = document.createTextNode(JSON.stringify(exportModelData));
      // par.appendChild(text);
      // w.document.body.appendChild(par);

      return exportModelData
    },

    set_z_coords_to_value: function() {
      console.log('coord_z:'+this.z_coords);

      if(this.z_coords){
        for( let i = this.scene.children.length - 1; i >= 0; i--) { 
          let obj = this.scene.children[i];
          
          if(obj.name.startsWith('text_')) {
            obj.position.z = this.z_coords
          }
          
          if(obj.name.startsWith('shape_')) {
            obj.position.z = this.z_coords
          }
        }
      }

    },

    // onMouseMove: function(event) {
    //   this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    //   this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    //   console.log(this.mouse.x.toFixed(3)+'|'+this.mouse.y.toFixed(3));
    // },

    // onDragEvent: function(e) {
    //   this.raycaster.setFromCamera(this.mouse, this.camera);
    //   this.raycaster.ray.intersectPlane(this.plane, this.intersects);
    //   e.object.position.set(this.intersects.x, this.intersects.y, this.intersects.z);

    //   console.log(this.intersects.x.toFixed(3)+'|'+this.intersects.y.toFixed(3)+'|'+this.intersects.z.toFixed(3));
    // },

  startApp() {
      // console.log('startApp')
      this.init();
      this.animate();
  }
},

}
</script>

<style scoped>
    /* #container {width:100%; height:100%;} */
</style>