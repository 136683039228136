import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
// import PrettyCheckbox from 'pretty-checkbox-vue';

Vue.config.productionTip = false

Vue.prototype.$API_URL = process.env.VUE_APP_API_URL; //'https://app.gravecreator.pl'
// Vue.prototype.$API_URL = 'https://192.168.1.19' //sudo socat tcp-l:443,fork,reuseaddr tcp:192.168.50.20:443

// Vue.component('p-check', PrettyRadio);
// Vue.use(PrettyCheckbox);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
